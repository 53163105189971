import React, { useState, useEffect } from "react";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import "./Hourlypacks.css";
import { Heart, MapPin, Calendar, Eye, LogIn } from "react-feather";
import { useNavigate } from "react-router-dom";

const Hourlypacks = () => {
  const [data, setData] = useState([]);
  const [turf, setTurf] = useState([]);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const userData = JSON.parse(sessionStorage.getItem("user"));

  const getdata = async () => {
    const response = await axios.get(
      "https://api.dremerz.net/api/lawnlinkregisteredturfsoffers"
    );
    setData(response.data);
    console.log(response.data, "getdata");
  };
  const getturfdata = async () => {
    const response = await axios.get(
      "https://api.dremerz.net/api/lawnlinkregisteredturfs"
    );
    setTurf(response.data);
    console.log(response.data, "turfdata");
  };
  const handleNavigate = (venue) => {
    if (userData) {
      const data = venue;
      navigate("/bookingordershome", { state: { data } });
    } else {
      navigate("/signin");
    }
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredUsers = data.filter((item) =>
    item.turfname.toLowerCase().includes(searchTerm)
  );

  useEffect(() => {
    getdata();
    getturfdata();
  }, []);

  return (
    <>
      <Container className="hourlypacks-container py-5">
        <div className="admin-search-bar">
          <input
            type="text"
            placeholder="Search Here..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="admin-search-input"
            style={{
              position: window.innerWidth <= 786 ? "relative" : "fixed",
              marginTop: window.innerWidth <= 786 ? "-42px" : "42px",
              marginRight: window.innerWidth <= 786 ? "" : "-65px",
              zIndex: window.innerWidth <= 786 ? "999" : "",
            }}
          />
        </div>

        <Row>
          {filteredUsers.map((pack, index) => (
            <Col key={index} md={4} sm={6} xs={12} className="mb-4">
              <Card
                className="hourlypack-card h-100 shadow"
                style={{ marginTop: window.innerWidth <= 786 ? "" : "70px" }}
              >
                <div className="hourlypack-img-wrapper">
                  <Card.Img
                    variant="top"
                    src={`https://api.dremerz.net/uploads/${pack.image}`}
                    alt="image"
                    className="hourlypack-img"
                  />
                </div>
                <Card.Body className="d-flex flex-column p-4">
                  <Card.Title className="text-center mb-3 turf-name">
                    {pack.turfname}
                  </Card.Title>
                  <Card.Text className="text-center">
                    <strong>Total Hours:</strong> {pack.totalhour}
                    <br />
                    <del>
                      <strong>Total Price: </strong>

                      {parseInt(pack.priceperhour) * parseInt(pack.totalhour)}
                    </del>
                    <br />
                    <strong className="offer-price">
                      <i className="fas fa-gift"></i> Offer Price:{" "}
                      {pack.totalprice}
                    </strong>
                  </Card.Text>
                  <a
                    href=""
                    onClick={() => {
                      handleNavigate(pack);
                    }}
                    className="btn btn-secondary w-100"
                  >
                    <Calendar className="me-2" /> Book Now
                  </a>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default Hourlypacks;
