import Ourteam from "./Ourteam";
import Vision from "./Vision";
import Footer from "../../Footer/Footer";
import Header from "../../Home/Header";
import "./Aboutushome.css";

const Aboutushome = () => {
  return (
    <>
      <Header></Header>
      <Ourteam></Ourteam>
      <Vision></Vision>

      <Footer></Footer>
    </>
  );
};
export default Aboutushome;
