import React, { useEffect, useState } from "react";
import Statics from "./Statics";
import Todaybookings from "./Todaybookings";
import Mybookings from "./Mybookings";
import Header from "../../Home/Header";
import "./Userdashboard.css";
import Footer from "../../Footer/Footer";
import axios from "axios";

const menuItems = [
  {
    href: "#",
    icon: "dashboard-icon.svg",
    label: "Dashboard",
    className: "active",
  },

  {
    href: "/profileedit",
    icon: "profile-icon.svg",
    label: "Profile Setting",
  },
];

const DashboardMenu = () => {
  const [userData, setUserData] = useState("");
  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("user"));
    console.log(userData);
  });
  return (
    <>
      <Header></Header>{" "}
      <div className="dashboard-section new-userdashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="dashboard-menu">
                <ul>
                  {menuItems.map((item, index) => (
                    <li key={index}>
                      <a href={item.href} className={item.className}>
                        <img src={`assets/img/icons/${item.icon}`} alt="Icon" />
                        <span>{item.label}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Todaybookings></Todaybookings>
      <Mybookings></Mybookings>
      <Statics></Statics>
      <Footer></Footer>
    </>
  );
};

export default DashboardMenu;
