import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import "./Header.css";

const Header = () => {
  const userData = JSON.parse(sessionStorage.getItem("user"));
  const navigate = useNavigate();
  const location = useLocation();
  const [profilePic, setProfilePic] = useState("assets/img/profilepic.png");

  const [showSubIcons, setShowSubIcons] = useState(false);
  const toggleSubIcons = () => {
    setShowSubIcons(!showSubIcons);
  };

  const navigateTo = (url) => {
    sessionStorage.setItem("location", "");
    if (userData) {
      navigate(url);
    } else {
      navigate("/signin");
    }
  };

  const getProfilePic = async () => {
    if (userData) {
      try {
        const response = await axios.get(
          `https://api.dremerz.net/api/lawnlinkuserprofile?email=${userData.email}`
        );
        const data = response.data[0]?.image;
        if (data) {
          setProfilePic(`https://api.dremerz.net/uploads/${data}`);
        }
      } catch (error) {
        console.error("Failed to fetch profile picture", error);
      }
    }
  };

  useEffect(() => {
    getProfilePic();
  }, [userData]);

  const isActive = (path) => location.pathname === path;

  return (
    <header className="header">
      <div className="container-fluid">
        <nav className="navbar navbar-expand-lg header-nav">
          <div className="navbar-header">
            <a href="/home" className="navbar-brand logo">
              {window.innerWidth > 786 ? (
                <img
                  src={`${process.env.PUBLIC_URL}/Listimg/Lawnlogo.jpeg`}
                  className="img-fluid lawn-logo"
                  alt="Logo"
                  style={{
                    display: "inline",
                  }}
                />
              ) : (
                <span
                  style={{
                    fontFamily: "'Lobster', 'Pacifico', cursive",
                    fontSize: "28px",
                    color: "#aaf40d",
                  }}
                >
                  LawnLink
                </span>
              )}
            </a>

            <div className="mobile-login-btn undesk">
              {userData ? (
                <a href="/myprofile" className="btn btn-white">
                  <img
                    src={profilePic}
                    style={{
                      height: "30px",
                      width: "30px",
                      borderRadius: "50%",
                      marginRight: "10px",
                    }}
                    alt="User Profile"
                  />
                  {userData.username}
                </a>
              ) : (
                <a href="/signin" className="btn btn-white">
                  <i className="feather-users"></i>
                  &nbsp;Login
                </a>
              )}
            </div>
          </div>

          <div className="main-menu-wrapper">
            <ul className="main-nav">
              <li className={isActive("/home") ? "active" : ""}>
                <a onClick={() => navigateTo("/home")}>Home</a>
              </li>
              <li
                className={`has-submenu ${
                  isActive("/shopping") ||
                  isActive("/hourlypackage") ||
                  isActive("/availableoffers")
                    ? "active"
                    : ""
                }`}
              >
                <a href="#">
                  Turfs <i className="fas fa-chevron-down"></i>
                </a>
                <ul className="submenu">
                  <li>
                    <a href="/shopping">Turf List</a>
                  </li>
                  <li>
                    <a href="/hourlypackage">Hourly Package</a>
                  </li>
                  <li>
                    <a href="/availableoffers">Available Offers</a>
                  </li>
                </ul>
              </li>
              <li className={isActive("/events") ? "active" : ""}>
                <a onClick={() => navigateTo("/events")}>Events</a>
              </li>
              <li className={isActive("/gyms") ? "active" : ""}>
                <a href="/gyms">Gyms</a>
              </li>
              <li className={isActive("/mybookings") ? "active" : ""}>
                <a onClick={() => navigateTo("/mybookings")}>Bookings</a>
              </li>
              <li className={isActive("/contactus") ? "active" : ""}>
                <a href="/contactus">Contact Us</a>
              </li>
            </ul>
          </div>

          <div className="nav header-navbar-rht">
            <div className="mobile-login-btn">
              {userData ? (
                <a href="/myprofile" className="btn btn-white">
                  <i className="feather-user"></i>
                  {userData.username}
                </a>
              ) : (
                <a href="/signin" className="btn btn-white">
                  <i className="feather-users"></i>
                  &nbsp;Login
                </a>
              )}
            </div>
            &nbsp;
            <img
              src={profilePic}
              style={{ height: "50px", width: "50px", borderRadius: "50%" }}
              alt="User Profile"
            />
          </div>
        </nav>
      </div>

      {/* Bottom Mobile Navigation Icons */}
      <div className="bottom-nav">
        <button onClick={() => navigateTo("/home")}>
          <i className="fas fa-home"></i>
        </button>
        <div>
          <button
            className="btn btn-secondary dropdown-toggle"
            type="button"
            id="dropupMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fas fa-football-ball"></i>
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropupMenuButton">
            <li>
              <button
                className="dropdown-item"
                onClick={() => navigateTo("/shopping")}
              >
                <i className="fas fa-solid fa-baseball-bat-ball"></i>{" "}
                <span
                  style={{
                    color: "black",
                    fontSize: "18px",
                    marginLeft: "20px",
                  }}
                >
                  Book Now
                </span>
              </button>
            </li>
            <li>
              <button
                className="dropdown-item"
                onClick={() => navigateTo("/hourlypackage")}
              >
                <i className="fa-solid fa-clock"></i>{" "}
                <span
                  style={{
                    color: "black",
                    fontSize: "18px",
                    marginLeft: "20px",
                  }}
                >
                  Hourly Packs
                </span>
              </button>
            </li>
            <li>
              <button
                className="dropdown-item"
                onClick={() => navigateTo("/availableoffers")}
              >
                <i className="fas fa-solid fa-signs-post"></i>{" "}
                <span
                  style={{
                    color: "black",
                    fontSize: "18px",
                    marginLeft: "20px",
                  }}
                >
                  {" "}
                  View Offers
                </span>
              </button>
            </li>
          </ul>
        </div>
        <button onClick={() => navigateTo("/events")}>
          <i className="fas fa-solid fa-trophy"></i>
        </button>
        <button onClick={() => navigateTo("/gyms")}>
          <i className="fas fa-solid fa-dumbbell"></i>
        </button>
        <button onClick={() => navigateTo("/mybookings")}>
          <i className="fas fa-solid fa-calendar-check"></i>
        </button>
        <button onClick={() => navigateTo("/contactus")}>
          <i className="fas fa-solid fa-file-signature"></i>
        </button>
      </div>
    </header>
  );
};

export default Header;
