import { useEffect, useState } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import "./Privacypolicy.css";

const PrivacyPolicy = () => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
  });
  const [privacyPolicies, setPrivacyPolicies] = useState([]);
  const [editData, setEditData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (isEditing) {
        await axios.put(
          `https://api.dremerz.net/api/lawnlinkhomeExplorePrivacypolicy/${editData.id}`,
          formData
        );
        alert("Privacy Policy Updated Successfully");
      } else {
        await axios.post(
          "https://api.dremerz.net/api/lawnlinkhomeExplorePrivacypolicy",
          formData
        );
        alert("Privacy Policy Saved Successfully");
      }

      setFormData({
        title: "",
        description: "",
      });
      setShowModal(false);
      getData(); // Refresh the list
    } catch (error) {
      console.log("Failed to save data", error);
    }
  };

  const getData = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/lawnlinkhomeExplorePrivacypolicy"
      );
      setPrivacyPolicies(response.data);
    } catch (error) {
      console.log("Failed to fetch data", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleEdit = (policy) => {
    setEditData(policy);
    setFormData({
      title: policy.title,
      description: policy.description,
    });
    setIsEditing(true);
    setShowModal(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this entry?")) {
      try {
        await axios.delete(
          `https://api.dremerz.net/api/lawnlinkhomeExplorePrivacypolicy/${id}`
        );
        alert("Privacy Policy deleted successfully");
        getData();
      } catch (error) {
        console.log("Failed to delete entry", error);
      }
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setFormData({
      title: "",
      description: "",
    });
    setIsEditing(false);
  };

  return (
    <>
      <div className="privacy-policy-container">
        <h2>{isEditing ? "Edit Privacy Policy" : "Add Privacy Policy"}</h2>
        <form onSubmit={handleSubmit} className="privacy-policy-form">
          <div className="mb-3">
            <label htmlFor="title" className="form-label">
              Title
            </label>
            <input
              type="text"
              className="form-control"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              placeholder="Enter title"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="description" className="form-label">
              Description
            </label>
            <input
              type="text"
              className="form-control"
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              placeholder="Enter description"
            />
          </div>

          <button type="submit" className="btn-primary">
            {isEditing ? "Update" : "Save"}
          </button>
        </form>
      </div>

      {/* Preview table with edit and delete options */}
      <div className="container">
        <div className="privacy-policy-preview-container">
          <h3>Privacy Policies</h3>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Title</th>
                <th>Description</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {privacyPolicies.map((policy, index) => (
                <tr key={policy.id}>
                  <td>{index + 1}</td>
                  <td>{policy.title}</td>
                  <td
                    style={{
                      wordBreak: "break-word",
                      maxWidth: "300px",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {policy.description}
                  </td>
                  <td>
                    <button
                      className="btn btn-primary"
                      onClick={() => handleEdit(policy)}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() => handleDelete(policy.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Edit Modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? "Edit Privacy Policy" : "Add Privacy Policy"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="title" className="form-label">
                Title
              </label>
              <input
                type="text"
                className="form-control"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="description" className="form-label">
                Description
              </label>
              <input
                type="text"
                className="form-control"
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
              />
            </div>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              {isEditing ? "Update" : "Save"}
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PrivacyPolicy;
