import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Header from "../Home/Header";
import "./Events.css";
import Footer from "../Footer/Footer";
import axios from "axios";

const Services = () => {
  const [activeTab, setActiveTab] = useState("AllEvents");
  const [servicesData, setSservicesData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const userData = JSON.parse(sessionStorage.getItem("user"));

  async function deletePastMatches() {
    const currentDateTime = new Date().toISOString();

    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/lawnlinkeventlist"
      );
      const matches = response.data;

      for (const match of matches) {
        if (new Date(match.dateTime) < new Date(currentDateTime)) {
          await axios.delete(
            `https://api.dremerz.net/api/lawnlinkeventlist/${match.id}`
          );
          console.log(`Deleted match with ID: ${match.id}`);
        }
      }
    } catch (error) {
      console.error("Error fetching or deleting matches:", error);
    }
  }

  function scheduleDailyDeletion() {
    setInterval(() => {
      const now = new Date();

      if (now.getHours() === 17 && now.getMinutes() === 28) {
        console.log("Running daily match cleanup");
        deletePastMatches();
      }
    }, 60000);
  }

  scheduleDailyDeletion();

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const fetchData = async (location) => {
    const response = await axios.get(
      `https://api.dremerz.net/api/lawnlinkeventlist${
        location != "" ? "?location=" + location : ""
      }`
    );
    const data = response.data;

    console.log(
      data.filter((item) => {
        if (item.hostBy == "turfevent") return item;
      })
    );

    var AllEvents = {
      AllEvents: data,
      Turfs: data.filter((item) => {
        if (item.hostBy == "turfevent") return item;
      }),
      Academy: data.filter((item) => {
        if (item.hostBy == "academyevent") return item;
      }),
    };
    setSservicesData(AllEvents);
  };

  const loadScript = (src) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => resolve(true);
      script.onerror = () => reject(false);
      document.body.appendChild(script);
    });
  };

  const handlepayment = async (service) => {
    const entryFee = service.entryFee;

    if (!entryFee) {
      alert("Something went wrong... try again later.");
      return;
    }

    try {
      const loaded = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!loaded) {
        alert("Failed to load Razorpay SDK. Please check your connection.");
        return;
      }

      const options = {
        key: "rzp_live_D21nobGzfuqZQV",
        key_secret: "AL7KBgMIE4Ed3fM0o9QxCqot",
        amount: entryFee * 100,
        currency: "INR",
        name: "LawnLink",
        description: "Bookings",
        handler: async (response) => {
          console.log(response);
          alert(response.razorpay_payment_id);
          const rid = response.razorpay_payment_id;
          await handleSavePayment(rid, service);
        },
        prefill: {
          name: userData.username,
          email: userData.email,
          contact: userData.mobileNumber,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#167C82",
        },
      };

      const pay = new window.Razorpay(options);
      pay.open();
    } catch (error) {
      console.error("Payment error", error);
      alert(
        "An error occurred while processing your payment. Please try again."
      );
    }
  };

  const handleSavePayment = async (rid, service) => {
    const paymentData = new FormData();
    paymentData.append("turfname", service?.turfname);
    paymentData.append("date", service?.dateTime || "");
    paymentData.append("totalprice", service?.entryFee);
    paymentData.append("name", userData.username);
    paymentData.append("email", userData.email);
    paymentData.append("mobile", userData.mobileNumber);
    paymentData.append("razorPayID", rid);
    paymentData.append("time", service?.dateTime);
    const getCurrentDateTime = () => {
      const now = new Date();

      // Format date and time
      const date = String(now.getDate()).padStart(2, "0"); // Day
      const month = String(now.getMonth() + 1).padStart(2, "0"); // Month (0-11)
      const year = now.getFullYear(); // Year
      const hours = String(now.getHours()).padStart(2, "0"); // Hours
      const minutes = String(now.getMinutes()).padStart(2, "0"); // Minutes
      const seconds = String(now.getSeconds()).padStart(2, "0"); // Seconds

      return `${date}+${month}+${year}_${hours}${minutes}${seconds}`;
    };
    const secretId = `SecIdLL+${getCurrentDateTime()}+${String(
      new Date().getSeconds()
    ).padStart(2, "0")}`;
    paymentData.append("secretId", secretId);

    try {
      const lastBookingId = await getLastBookingId();
      const newBookingIdNumber = lastBookingId + 1;
      const currentDate = new Date();
      const newBookingId = `LLevent${String(currentDate.getDate()).padStart(
        2,
        "0"
      )}${String(currentDate.getMonth() + 1).padStart(
        2,
        "0"
      )}${currentDate.getFullYear()}${String(newBookingIdNumber).padStart(
        2,
        "0"
      )}`;

      paymentData.append("bookingid", newBookingId);

      const response = await axios.post(
        "https://api.dremerz.net/api/lawnlinkPayments",
        paymentData
      );

      if (response.status === 200) {
        alert("Payment successful");
      } else {
        alert("Payment failed. Please try again.");
      }
    } catch (error) {
      console.log("Payment failed", error);
      alert("Payment failed. Please try again.");
    }
  };

  const getLastBookingId = async () => {
    try {
      const response = await fetch(
        "https://api.dremerz.net/api/lawnlinkPayments"
      );
      if (!response.ok) throw new Error("Network response was not ok");

      const data = await response.json();
      const bookingIds = data.map((item) => item.id);
      const lastId =
        bookingIds.length > 0 ? Math.max(...bookingIds.map(Number)) : 0;

      return lastId;
    } catch (error) {
      console.error("Error fetching booking IDs:", error);
      return 0;
    }
  };

  useEffect(() => {
    var location1 = sessionStorage.getItem("location");

    fetchData(location1);
  }, []);

  return (
    <>
      <Header />
      <br></br>
      <div className="admin-search-bar">
        <input
          type="text"
          placeholder="Search Here..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="admin-search-input"
          style={{
            position: window.innerWidth <= 786 ? "relative" : "fixed",
            marginRight: window.innerWidth <= 786 ? "" : "5px",
            marginTop: window.innerWidth <= 786 ? "-20px" : "66px",
            zIndex: window.innerWidth <= 786 ? "999" : "",
          }}
        />
      </div>
      <div
        className="content new-eventshome"
        style={{ marginTop: window.innerWidth <= 786 ? "-10px" : "" }}
      >
        <div className="container">
          <section className="services">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              {Object.keys(servicesData).map((tab) => (
                <li className="nav-item" role="presentation" key={tab}>
                  <button
                    className={`nav-link ${activeTab === tab ? "active" : ""}`}
                    id={`pills-${tab}-tab`}
                    data-bs-toggle="pill"
                    data-bs-target={`#pills-${tab}`}
                    type="button"
                    role="tab"
                    aria-controls={`pills-${tab}`}
                    aria-selected={activeTab === tab}
                    onClick={() => setActiveTab(tab)}
                  >
                    {tab.charAt(0).toUpperCase() + tab.slice(1)}
                  </button>
                </li>
              ))}
            </ul>

            <div className="tab-content" id="pills-tabContent">
              {Object.entries(servicesData).map(([tab, services]) => (
                <div
                  className={`tab-pane fade ${
                    activeTab === tab ? "show active" : ""
                  }`}
                  id={`pills-${tab}`}
                  role="tabpanel"
                  aria-labelledby={`pills-${tab}-tab`}
                  key={tab}
                >
                  <div className="row">
                    {services
                      .filter(
                        (item) =>
                          item.turfname.toLowerCase().includes(searchTerm) ||
                          item.location.toLowerCase().includes(searchTerm)
                      )
                      .map((service) => (
                        <div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-4">
                          <div className="card border-0 shadow-sm">
                            {service.dateTime && (
                              <p className="card-text">
                                <strong>Date and Time:</strong>{" "}
                                {service.dateTime}
                              </p>
                            )}
                            <a href={service.link} className="d-block">
                              <img
                                src={`https://api.dremerz.net/uploads/${service.tournamentPoster}`}
                                className="card-img-top"
                                alt={service.title}
                                style={{ height: "200px" }}
                              />
                            </a>
                            <div className="card-body-events fixed-card-height-events">
                              <h5 className="card-title">{service.title}</h5>
                              <p className="card-text">{service.description}</p>
                              {service.matchType && (
                                <p className="card-text">
                                  <strong>Match Type:</strong>{" "}
                                  {service.matchType}
                                </p>
                              )}
                              {service.entryFee && (
                                <p className="card-text">
                                  <strong>Entry Fees:</strong>{" "}
                                  {service.entryFee}
                                </p>
                              )}
                              {service.rules && (
                                <p className="card-text">
                                  <strong>Rules:</strong> {service.rules}
                                </p>
                              )}
                              {service.turfname && (
                                <p className="card-text">
                                  <strong>Turf Name:</strong> {service.turfname}
                                </p>
                              )}
                              {service.location && (
                                <p className="card-text">
                                  <strong>Location:</strong> {service.location}
                                </p>
                              )}
                              {service.fulladdress && (
                                <p className="card-text">
                                  <strong>Full Address:</strong>{" "}
                                  {service.fulladdress}
                                </p>
                              )}
                              {service.contactNo && (
                                <p className="card-text">
                                  <strong>Contact No:</strong>{" "}
                                  {service.contactNo}
                                </p>
                              )}
                              {service.hostBy && (
                                <p className="card-text">
                                  <strong>Host By:</strong> {service.hostBy}
                                </p>
                              )}
                              <button
                                className="btn btn-secondary book-button"
                                onClick={() => handlepayment(service)}
                              >
                                Book Now
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Services;
