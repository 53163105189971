import React, { useState, useEffect } from "react";

import "./Myprofile.css";
import Header from "../Home/Header";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Myprofile.css";

export default function ProfilePage() {
  const [EditEventData, setEditEventData] = useState(null);
  const [allProfileData, setAllProfileData] = useState(null);

  const [userData, setUserData] = useState({});
  const [profileData, setProfileData] = useState("");
  const [turfData, setTurfData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isEditEventModalOpen, setIsEditEventModalOpen] = useState(false);
  const [isEditGymModalOpen, setIsEditGymModalOpen] = useState(false);

  const [editFormData, setEditFormData] = useState({});
  const [editGymData, setEditGymData] = useState({});

  const [img, setImg] = useState(null);
  const [img1, setImg1] = useState(null);
  const [img2, setImg2] = useState(null);

  const navigate = useNavigate();

  const [gymData, setGymData] = useState([]);
  const getgymdata = async (email) => {
    try {
      const response = await axios.get(
        `https://api.dremerz.net/api/lawnlinkregisteredgyms?officialemail=${email}`
      );
      setGymData(response.data);
    } catch (error) {}
  };

  const handleInputArrayChange = (e, index, field) => {
    const { name, value } = e.target;
    const newOverview = [...editFormData[field]];
    newOverview[index][name] = value;
    setEditFormData({ ...editFormData, [field]: newOverview });
  };
  const handleInputGymArrayChange = (e, index, field) => {
    const { name, value } = e.target;
    const newOverview = [...editGymData[field]];
    newOverview[index][name] = value;
    setEditGymData({ ...editGymData, [field]: newOverview });
  };

  const addOverviewField = (key, field) => {
    setEditFormData({
      ...editFormData,
      [key]: [...editFormData[key], { [field]: "" }],
    });
  };

  const removeOverviewField = (index) => {
    const newOverview = editFormData.overview.filter((_, i) => i !== index);
    setEditFormData({ ...editFormData, overview: newOverview });
  };
  const removeIncludesField = (index) => {
    const newOverview = editFormData.includes.filter((_, i) => i !== index);
    setEditFormData({ ...editFormData, includes: newOverview });
  };
  const removeRulesField = (index) => {
    const newOverview = editFormData.rules.filter((_, i) => i !== index);
    setEditFormData({ ...editFormData, rules: newOverview });
  };
  const removeAmenitiesField = (index) => {
    const newOverview = editFormData.amenities.filter((_, i) => i !== index);
    setEditFormData({ ...editFormData, amenities: newOverview });
  };

  const addOverviewFieldGym = (key, field) => {
    setEditGymData({
      ...editGymData,
      [key]: [...editGymData[key], { [field]: "" }],
    });
  };

  const removeOverviewFieldGym = (index) => {
    const newOverview = editGymData.overview.filter((_, i) => i !== index);
    setEditGymData({ ...editGymData, overview: newOverview });
  };
  const removeIncludesFieldGym = (index) => {
    const newOverview = editGymData.includes.filter((_, i) => i !== index);
    setEditGymData({ ...editGymData, includes: newOverview });
  };
  const removeRulesFieldGym = (index) => {
    const newOverview = editGymData.rules.filter((_, i) => i !== index);
    setEditGymData({ ...editGymData, rules: newOverview });
  };
  const removeAmenitiesFieldGym = (index) => {
    const newOverview = editGymData.amenities.filter((_, i) => i !== index);
    setEditGymData({ ...editGymData, amenities: newOverview });
  };

  const handleLogout = () => {
    sessionStorage.removeItem("user");
    window.location.href = "/signin";
  };

  const handleProfileEdit = () => {
    navigate("/profileedit");
  };

  const getalldata = async () => {
    const response = await axios.get(
      "https://api.dremerz.net/api/lawnlinkuserprofile"
    );
    setAllProfileData(response.data);
  };

  const getdata = async (email) => {
    console.log(email, "emailaa");

    const response = await axios.get(
      `https://api.dremerz.net/api/lawnlinkuserprofile?email=${email}`
    );
    setProfileData(response.data[0]);
  };

  const getturfdata = async (email) => {
    console.log(email, "email");

    try {
      const response = await axios.get(
        `https://api.dremerz.net/api/lawnlinkregisteredturfs?officialemail=${email}`
      );
      setTurfData(response.data);
    } catch (error) {
      console.log("something went wrong");
    }
  };

  const geteventdata = async (email) => {
    console.log(email, "email");

    try {
      const response = await axios.get(
        `https://api.dremerz.net/api/lawnlinkeventlist?officialemail=${email}`
      );
      setEventData(response.data);
    } catch (error) {
      console.log("something went wrong");
    }
  };

  const handleEditTurf = (item) => {
    let data = { ...item };

    const parseJSONSafely = (value) => {
      try {
        return JSON.parse(value);
      } catch (e) {
        return value;
      }
    };

    data.overview = parseJSONSafely(item.overview);
    data.includes = parseJSONSafely(item.includes);
    data.rules = parseJSONSafely(item.rules);
    data.amenities = parseJSONSafely(item.amenities);

    setEditFormData(data);

    console.log(item.overview, turfData);

    setIsEditModalOpen(true);
  };
  const handleEditGym = (item) => {
    let data = { ...item };

    const parseJSONSafely = (value) => {
      try {
        return JSON.parse(value);
      } catch (e) {
        return value;
      }
    };

    data.overview = parseJSONSafely(item.overview);
    data.includes = parseJSONSafely(item.includes);
    data.rules = parseJSONSafely(item.rules);
    data.amenities = parseJSONSafely(item.amenities);

    setEditGymData(data);

    console.log(item.overview, turfData);

    setIsEditGymModalOpen(true);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditFormData({
      ...editFormData,
      [name]: value,
    });
  };
  const handleInputGymChange = (event) => {
    const { name, value } = event.target;
    setEditGymData({
      ...editGymData,
      [name]: value,
    });
  };
  const handleInputImageChange = (event) => {
    const images = event.target.files[0];
    console.log(images);
    setImg(images);
  };
  const handleInputImageChangeGym = (event) => {
    const images = event.target.files[0];
    console.log(images);
    setImg2(images);
  };
  const handleSaveEditTurf = async () => {
    try {
      var data = new FormData();
      data.append("rating", editFormData.rating);
      data.append("review", editFormData.review);
      data.append("turfname", editFormData.turfname);
      data.append("priceperhour", editFormData.priceperhour);
      data.append("location", editFormData.location.toLowerCase());
      data.append("contactNo", editFormData.contactNo);
      data.append("description", editFormData.description);
      data.append("fulladdress", editFormData.fulladdress);
      data.append("officialemail", editFormData.officialemail);
      data.append("pitchsize", editFormData.pitchsize);
      data.append("overview", JSON.stringify(editFormData.overview));
      data.append("includes", JSON.stringify(editFormData.includes));
      data.append("rules", JSON.stringify(editFormData.rules));
      data.append("amenities", JSON.stringify(editFormData.amenities));
      if (img) {
        data.append("image", img);
      }

      await axios.put(
        `https://api.dremerz.net/api/lawnlinkregisteredturfs/${editFormData.id}`,
        data
      );
      alert("Turf Updated Successfully");

      setEditFormData({});
      setIsEditModalOpen(false);
      getturfdata();
    } catch (error) {
      console.error("Failed to update turf:", error);
    }
  };
  const handleSaveEditTurfGym = async () => {
    try {
      var data = new FormData();
      data.append("rating", editGymData.rating);
      data.append("review", editGymData.review);
      data.append("turfname", editGymData.turfname);
      data.append("priceperhour", editGymData.priceperhour);
      data.append("location", editGymData.location.toLowerCase());
      data.append("contactNo", editGymData.contactNo);
      data.append("description", editGymData.description);
      data.append("fulladdress", editGymData.fulladdress);
      data.append("officialemail", editGymData.officialemail);
      data.append("overview", JSON.stringify(editGymData.overview));
      data.append("includes", JSON.stringify(editGymData.includes));
      data.append("rules", JSON.stringify(editGymData.rules));
      data.append("amenities", JSON.stringify(editGymData.amenities));
      if (img2) {
        data.append("image", img2);
      }

      await axios.put(
        `https://api.dremerz.net/api/lawnlinkregisteredgyms/${editGymData.id}`,
        data
      );
      alert("Gym Updated Successfully");

      setEditGymData({});
      setIsEditGymModalOpen(false);
      getgymdata();
    } catch (error) {
      console.error("Failed to update Gym:", error);
    }
  };

  const handleEventInputChange = (event) => {
    const { name, value } = event.target;
    setEditEventData({
      ...EditEventData,
      [name]: value,
    });
  };
  const handleEventInputImageChange = (event) => {
    const images = event.target.files[0];
    console.log(images);
    setImg1(images);
  };
  const handleSaveEditEvent = async () => {
    try {
      const data = new FormData();
      data.append("matchType", EditEventData.matchType);
      data.append("entryFee", EditEventData.entryFee);
      data.append("dateTime", EditEventData.dateTime);
      data.append("rules", EditEventData.rules);
      data.append("hostBy", EditEventData.hostBy);
      data.append("location", EditEventData.location.toLowerCase());
      data.append("contactNo", EditEventData.contactNo);
      data.append("description", EditEventData.description);
      data.append("turfname", EditEventData.turfname);
      data.append("fulladdress", EditEventData.fulladdress);

      if (img1) {
        data.append("tournamentPoster", img1);
      }

      await axios.put(
        `https://api.dremerz.net/api/lawnlinkeventlist/${EditEventData.id}/`,
        data
      );
      alert("Event Updated Successfully");
      setEditEventData({});
      setIsEditEventModalOpen(false);
      geteventdata();
    } catch (error) {
      console.error("Failed to update event:", error);
    }
  };

  const handleDeleteTurf = async (id) => {
    const confimation = window.confirm(
      "Are You Sure Want To Delete These Data"
    );
    if (!confimation) {
      return;
    } else {
      try {
        await axios.delete(
          `https://api.dremerz.net/api/lawnlinkregisteredturfs/${id}`
        );
        getturfdata();
        alert("Turf Deleted Successfully");
      } catch (error) {
        console.error("Failed to delete turf:", error);
      }
    }
  };
  const handleDeleteGym = async (id) => {
    const confimation = window.confirm(
      "Are You Sure Want To Delete These Data"
    );
    if (!confimation) {
      return;
    } else {
      try {
        await axios.delete(
          `https://api.dremerz.net/api/lawnlinkregisteredgyms/${id}`
        );
        getgymdata();
        alert("Turf Deleted Successfully");
      } catch (error) {
        console.error("Failed to delete turf:", error);
      }
    }
  };

  // eventfunctions
  const handleEditEvent = (item) => {
    setEditEventData(item);
    setIsEditEventModalOpen(true);
  };

  const handleDeleteEvent = async (id) => {
    const confirmation = window.confirm(
      "Are You Sure Want To Delete These Data"
    );
    if (!confirmation) {
      return;
    } else {
      try {
        await axios.delete(
          `https://api.dremerz.net/api/lawnlinkeventlist/${id}`
        );

        geteventdata(); // Refresh event data
        alert("Event Deleted Successfully");
      } catch (error) {
        console.error("Failed to delete event:", error);
      }
    }
  };

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    setUserData(user || {});
    getalldata();
    getdata(user.email);
    getturfdata(user.email);
    geteventdata(user.email);
    getgymdata(user.email);
  }, [isEditModalOpen, isEditEventModalOpen, isEditGymModalOpen]);

  return (
    <>
      <Header />

      <section className="myprofile-section">
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-4">
              <div className="card mb-4">
                <div className="card-body text-center">
                  <a href="/profileedit">
                    <img
                      src={`https://api.dremerz.net/uploads/${profileData?.image}`}
                      alt="Update Profile Image..."
                      className="rounded-circle avatar-image img-fluid"
                      style={{
                        height: "150px",
                        width: "150px",
                        marginBottom: "50px",
                      }}
                    />
                  </a>

                  <div className="d-flex justify-content-center mb-2">
                    <button
                      className="btn btn-outline-success ms-1"
                      onClick={handleProfileEdit}
                    >
                      Profile Edit
                    </button>
                    <button
                      className="btn btn-outline-danger ms-1"
                      onClick={handleLogout}
                    >
                      Logout
                    </button>
                  </div>
                </div>
              </div>

              <div className="card mb-4 mb-lg-0">
                <div className="card-body p-0">
                  <ul className="list-group rounded-3">
                    <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                      <a
                        href="/mybookings"
                        className="d-flex align-items-center text-decoration-none"
                      >
                        <i className="fas fa-user fa-lg text-warning"></i>
                        <span className="ms-3">My Bookings</span>
                      </a>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                      <a
                        href="/changepassword"
                        className="d-flex align-items-center text-decoration-none"
                      >
                        <i className="fas fa-key fa-lg text-info"></i>
                        <span className="ms-3">Manage Password</span>
                      </a>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                      <a
                        href="/contactus"
                        className="d-flex align-items-center text-decoration-none"
                      >
                        <i className="fas fa-life-ring fa-lg text-danger"></i>
                        <span className="ms-3">Help and Support</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-8">
              <div className="card mb-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Full Name</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">
                        {profileData?.firstname} {profileData?.lastname}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Email</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">{userData.email}</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Mobile</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">{userData.mobileNumber}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="card mb-4 mb-md-0">
                    <div className="card-body">
                      <p className="mb-4">
                        {userData?.isVerify == "1" ? (
                          <a
                            className="text-primary font-italic me-1"
                            href="/turfregistration"
                          >
                            To Register New Turfs Click Here
                          </a>
                        ) : (
                          <a
                            className="text-primary font-italic me-1 disable-regi"
                            href=""
                          >
                            To Register New Turfs Contact ( 7904922816 )
                          </a>
                        )}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="card mb-4 mb-md-0">
                    <div className="card-body">
                      <p className="mb-4">
                        {userData?.isVerify == "1" ? (
                          <a
                            className="text-primary font-italic me-1"
                            href="/turfhost"
                          >
                            To Register New Events Click Here
                          </a>
                        ) : (
                          <a
                            className="text-primary font-italic me-1 disable-regi"
                            href=""
                          >
                            To Register New Events Contact ( 9488739021 )
                          </a>
                        )}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="card mb-4 mb-md-0">
                    <div className="card-body">
                      <p className="mb-4">
                        {userData?.isVerify == "1" ? (
                          <a
                            className="text-primary font-italic me-1"
                            href="/gymhost"
                          >
                            To Register New Gyms Click Here
                          </a>
                        ) : (
                          <a
                            className="text-primary font-italic me-1 disable-regi"
                            href=""
                          >
                            To Register New Gyms Contact ( 9488739021 )
                          </a>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mb-4">
                <div className="card-body">
                  <h5 className="text-center">Registered Turfs</h5>
                  {turfData.length === 0 ? (
                    <p className="text-center">No turfs registered.</p>
                  ) : (
                    <ul className="list-group">
                      {turfData.map((item) => (
                        <li
                          key={item.id}
                          className="list-group-item d-flex justify-content-between align-items-center p-3"
                        >
                          <div>{item.turfname}</div>
                          <div>
                            <button
                              className="btn btn-outline-success ms-1"
                              onClick={() => handleEditTurf(item)}
                            >
                              Edit
                            </button>
                            <button
                              className="btn btn-outline-danger ms-1"
                              onClick={() => handleDeleteTurf(item.id)}
                            >
                              Delete
                            </button>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>

              <div className="card mb-4">
                <div className="card-body">
                  <h5 className="text-center">Registered Events</h5>
                  {eventData.length === 0 ? (
                    <p className="text-center">No events registered.</p>
                  ) : (
                    <ul className="list-group">
                      {eventData.map((item) => (
                        <li
                          key={item.id}
                          className="list-group-item d-flex justify-content-between align-items-center p-3"
                        >
                          <div>{item.matchType}</div>
                          <div>
                            <button
                              className="btn btn-outline-success ms-1"
                              onClick={() => handleEditEvent(item)}
                            >
                              Edit
                            </button>
                            <button
                              className="btn btn-outline-danger ms-1"
                              onClick={() => handleDeleteEvent(item.id)}
                            >
                              Delete
                            </button>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>

              <div className="card mb-4">
                <div className="card-body">
                  <h5 className="text-center">Registered Gyms</h5>
                  {gymData.length === 0 ? (
                    <p className="text-center">No Gyms registered.</p>
                  ) : (
                    <ul className="list-group">
                      {gymData.map((item) => (
                        <li
                          key={item.id}
                          className="list-group-item d-flex justify-content-between align-items-center p-3"
                        >
                          <div>{item.turfname}</div>
                          <div>
                            <button
                              className="btn btn-outline-success ms-1"
                              onClick={() => handleEditGym(item)}
                            >
                              Edit
                            </button>
                            <button
                              className="btn btn-outline-danger ms-1"
                              onClick={() => handleDeleteGym(item.id)}
                            >
                              Delete
                            </button>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />

      {/* turfedit */}
      <div
        className={`modal fade ${isEditModalOpen ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editTurfModalLabel"
        aria-hidden="true"
        style={{ display: isEditModalOpen ? "block" : "none" }}
        data-bs-backdrop="static"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editTurfModalLabel">
                Edit Turf
              </h5>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => setIsEditModalOpen(false)}
              ></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label className="form-label">Turf Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="turfname"
                  value={editFormData?.turfname || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Price per Hour</label>
                <input
                  type="number"
                  className="form-control"
                  name="priceperhour"
                  value={editFormData?.priceperhour || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Location</label>
                <input
                  type="text"
                  className="form-control"
                  name="location"
                  value={editFormData?.location || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Contact Number</label>
                <input
                  type="text"
                  className="form-control"
                  name="contactNo"
                  value={editFormData?.contactNo || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Description</label>
                <input
                  type="text"
                  className="form-control"
                  name="description"
                  value={editFormData?.description || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Full Address</label>
                <input
                  type="text"
                  className="form-control"
                  name="fulladdress"
                  value={editFormData?.fulladdress || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Official Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="officialemail"
                  value={editFormData?.officialemail || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Pitch Size</label>
                <input
                  type="text"
                  className="form-control"
                  name="pitchsize"
                  value={editFormData?.pitchsize || ""}
                  onChange={handleInputChange}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Overview</label>
                {editFormData.overview?.map((item, index) => (
                  <div key={index} className="d-flex mb-2">
                    <input
                      type="text"
                      className="form-control"
                      name="heading"
                      value={item.heading || ""}
                      onChange={(e) =>
                        handleInputArrayChange(e, index, "overview")
                      }
                    />
                    <button
                      type="button"
                      className="btn btn-danger ms-2"
                      onClick={() => removeOverviewField(index)}
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  className="btn btn-primary mt-2"
                  onClick={() => {
                    addOverviewField("overview", "heading");
                  }}
                >
                  Add New
                </button>
              </div>

              <div className="mb-3">
                <label className="form-label">Includes</label>
                {editFormData.includes?.map((item, index) => (
                  <div key={index} className="d-flex mb-2">
                    <input
                      type="text"
                      className="form-control"
                      name="item"
                      value={item.item || ""}
                      onChange={(e) =>
                        handleInputArrayChange(e, index, "includes")
                      }
                    />
                    <button
                      type="button"
                      className="btn btn-danger ms-2"
                      onClick={() => removeIncludesField(index)}
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  className="btn btn-primary mt-2"
                  onClick={() => {
                    addOverviewField("includes", "item");
                  }}
                >
                  Add New
                </button>
              </div>

              <div className="mb-3">
                <label className="form-label">Rules</label>
                {editFormData.rules?.map((item, index) => (
                  <div key={index} className="d-flex mb-2">
                    <input
                      type="text"
                      className="form-control"
                      name="rule"
                      value={item.rule || ""}
                      onChange={(e) =>
                        handleInputArrayChange(e, index, "rules")
                      }
                    />
                    <button
                      type="button"
                      className="btn btn-danger ms-2"
                      onClick={() => removeRulesField(index)}
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  className="btn btn-primary mt-2"
                  onClick={() => {
                    addOverviewField("rules", "item");
                  }}
                >
                  Add New
                </button>
              </div>

              <div className="mb-3">
                <label className="form-label">Amenities</label>
                {editFormData.amenities?.map((item, index) => (
                  <div key={index} className="d-flex mb-2">
                    <input
                      type="text"
                      className="form-control"
                      name="amenity"
                      value={item.amenity || ""}
                      onChange={(e) =>
                        handleInputArrayChange(e, index, "amenities")
                      }
                    />
                    <button
                      type="button"
                      className="btn btn-danger ms-2"
                      onClick={() => removeAmenitiesField(index)}
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  className="btn btn-primary mt-2 "
                  onClick={() => {
                    addOverviewField("amenities", "item");
                  }}
                >
                  Add New
                </button>
              </div>

              <div className="mb-3">
                <label className="form-label">Image Filename</label>
                <input
                  type="file"
                  className="form-control"
                  name="image"
                  accept="image/*"
                  onChange={handleInputImageChange}
                />
              </div>
              {editFormData.image || ""}
              <button
                type="button"
                className="btn btn-success"
                onClick={handleSaveEditTurf}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* editevent */}

      <div
        className={`modal fade ${isEditEventModalOpen ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editEventModalLabel"
        aria-hidden="true"
        style={{ display: isEditEventModalOpen ? "block" : "none" }}
        data-bs-backdrop="static"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editEventModalLabel">
                Edit Event
              </h5>

              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => setIsEditEventModalOpen(false)}
              ></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label className="form-label">matchType</label>
                <input
                  type="text"
                  className="form-control"
                  name="matchType"
                  value={EditEventData?.matchType || ""}
                  onChange={handleEventInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">entryFee</label>
                <input
                  type="text"
                  className="form-control"
                  name="entryFee"
                  value={EditEventData?.entryFee || ""}
                  onChange={handleEventInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">dateTime</label>
                <input
                  type="datetime-local"
                  className="form-control"
                  name="dateTime"
                  value={EditEventData?.dateTime || ""}
                  onChange={handleEventInputChange}
                  min={new Date().toISOString().slice(0, 16)}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">rules</label>
                <input
                  type="text"
                  className="form-control"
                  name="rules"
                  value={EditEventData?.rules || ""}
                  onChange={handleEventInputChange}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">hostBy</label>
                <input
                  type="text"
                  className="form-control"
                  name="hostBy"
                  value={EditEventData?.hostBy || ""}
                  onChange={handleEventInputChange}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">location </label>
                <input
                  type="text"
                  className="form-control"
                  name="location"
                  value={EditEventData?.location || ""}
                  onChange={handleEventInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Full Address </label>
                <input
                  type="text"
                  className="form-control"
                  name="fulladdress"
                  value={EditEventData?.fulladdress || ""}
                  onChange={handleEventInputChange}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">contactNo </label>
                <input
                  type="text"
                  className="form-control"
                  name="contactNo"
                  value={EditEventData?.contactNo || ""}
                  onChange={handleEventInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">description </label>
                <input
                  type="text"
                  className="form-control"
                  name="description"
                  value={EditEventData?.description || ""}
                  onChange={handleEventInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">turfname</label>
                <input
                  type="text"
                  className="form-control"
                  name="turfname"
                  value={EditEventData?.matchType || ""}
                  onChange={handleEventInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Image Filename</label>
                <input
                  type="file"
                  className="form-control"
                  name="image"
                  accept="image/*"
                  onChange={handleEventInputImageChange}
                />
              </div>
              {EditEventData?.tournamentPoster || ""}

              <button
                type="button"
                className="btn btn-success"
                onClick={handleSaveEditEvent}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* editgyms */}
      <div
        className={`modal fade ${isEditGymModalOpen ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editGymModalLabel"
        aria-hidden="true"
        style={{ display: isEditGymModalOpen ? "block" : "none" }}
        data-bs-backdrop="static"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editGymModalLabel">
                Edit Turf
              </h5>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => setIsEditGymModalOpen(false)}
              ></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label className="form-label">Gym Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="turfname"
                  value={editGymData?.turfname || ""}
                  onChange={handleInputGymChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Price per Hour</label>
                <input
                  type="number"
                  className="form-control"
                  name="priceperhour"
                  value={editGymData?.priceperhour || ""}
                  onChange={handleInputGymChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Location</label>
                <input
                  type="text"
                  className="form-control"
                  name="location"
                  value={editGymData?.location || ""}
                  onChange={handleInputGymChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Contact Number</label>
                <input
                  type="text"
                  className="form-control"
                  name="contactNo"
                  value={editGymData?.contactNo || ""}
                  onChange={handleInputGymChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Description</label>
                <input
                  type="text"
                  className="form-control"
                  name="description"
                  value={editGymData?.description || ""}
                  onChange={handleInputGymChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Full Address</label>
                <input
                  type="text"
                  className="form-control"
                  name="fulladdress"
                  value={editGymData?.fulladdress || ""}
                  onChange={handleInputGymChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Official Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="officialemail"
                  value={editGymData?.officialemail || ""}
                  onChange={handleInputGymChange}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Overview</label>
                {editGymData.overview?.map((item, index) => (
                  <div key={index} className="d-flex mb-2">
                    <input
                      type="text"
                      className="form-control"
                      name="heading"
                      value={item.heading || ""}
                      onChange={(e) =>
                        handleInputGymArrayChange(e, index, "overview")
                      }
                    />
                    <button
                      type="button"
                      className="btn btn-danger ms-2"
                      onClick={() => removeOverviewFieldGym(index)}
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  className="btn btn-primary mt-2"
                  onClick={() => {
                    addOverviewFieldGym("overview", "heading");
                  }}
                >
                  Add New
                </button>
              </div>

              <div className="mb-3">
                <label className="form-label">Includes</label>
                {editGymData.includes?.map((item, index) => (
                  <div key={index} className="d-flex mb-2">
                    <input
                      type="text"
                      className="form-control"
                      name="item"
                      value={item.item || ""}
                      onChange={(e) =>
                        handleInputGymArrayChange(e, index, "includes")
                      }
                    />
                    <button
                      type="button"
                      className="btn btn-danger ms-2"
                      onClick={() => removeIncludesFieldGym(index)}
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  className="btn btn-primary mt-2"
                  onClick={() => {
                    addOverviewFieldGym("includes", "item");
                  }}
                >
                  Add New
                </button>
              </div>

              <div className="mb-3">
                <label className="form-label">Rules</label>
                {editGymData.rules?.map((item, index) => (
                  <div key={index} className="d-flex mb-2">
                    <input
                      type="text"
                      className="form-control"
                      name="rule"
                      value={item.rule || ""}
                      onChange={(e) =>
                        handleInputGymArrayChange(e, index, "rules")
                      }
                    />
                    <button
                      type="button"
                      className="btn btn-danger ms-2"
                      onClick={() => removeRulesFieldGym(index)}
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  className="btn btn-primary mt-2"
                  onClick={() => {
                    addOverviewFieldGym("rules", "heading");
                  }}
                >
                  Add New
                </button>
              </div>

              <div className="mb-3">
                <label className="form-label">Amenities</label>
                {editGymData.amenities?.map((item, index) => (
                  <div key={index} className="d-flex mb-2">
                    <input
                      type="text"
                      className="form-control"
                      name="amenity"
                      value={item.amenity || ""}
                      onChange={(e) =>
                        handleInputGymArrayChange(e, index, "amenities")
                      }
                    />
                    <button
                      type="button"
                      className="btn btn-danger ms-2"
                      onClick={() => removeAmenitiesFieldGym(index)}
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  className="btn btn-primary mt-2 "
                  onClick={() => {
                    addOverviewFieldGym("amenities", "heading");
                  }}
                >
                  Add New
                </button>
              </div>

              <div className="mb-3">
                <label className="form-label">Image Filename</label>
                <input
                  type="file"
                  className="form-control"
                  name="image"
                  accept="image/*"
                  onChange={handleInputImageChangeGym}
                />
              </div>
              {editGymData.image || ""}
              <button
                type="button"
                className="btn btn-success"
                onClick={handleSaveEditTurfGym}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
