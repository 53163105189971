import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faPinterestP,
} from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import Slider from "react-slick";
import "./Ourteam.css";

const Ourteam = () => {
  const [data, setData] = useState([]);

  const getdata = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/lawnlinkOurTeam"
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getdata();
  }, []);

  return (
    <>
      <div className="ourteam-container" style={{ marginTop: "100px" }}>
        <section className="ourteam-section ">
          <div className="ourteam-inner-container">
            <div className="ourteam-heading aos" data-aos="fade-up">
              <h2>
                Our <span>Team</span>
              </h2>
            </div>

            <div className="ourteam-featured-slider-group" data-aos="fade-up">
              {data?.map((offer) => (
                <div
                  className="ourteam-featured-item"
                  key={offer.id}
                  style={{ marginLeft: "5%" }}
                >
                  <div className="ourteam-listing-item mb-0">
                    <div className="ourteam-listing-img">
                      <a href="https://www.instagram.com/lawn_link/?igsh=MTQwMTV6MHF5a3gxdg%3D%3D">
                        <img
                          src={`https://api.dremerz.net/uploads/${offer.imgSrc}`}
                          alt={offer.name}
                          className="ourteam-img"
                          style={{
                            borderRadius: "10px",
                          }}
                        />
                      </a>
                      <div className="ourteam-fav-item-venues">
                        <div className="ourteam-list-reviews coche-star"></div>
                      </div>
                      <div className="ourteam-hour-list">
                        <h5 className="ourteam-tag tag-primary">
                          {offer.name}
                        </h5>
                      </div>
                    </div>
                    <div className="ourteam-listing-content">
                      <span>{offer.designation}</span>
                      <h3>
                        <p className="ourteam-p">{offer.details}</p>
                      </h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Ourteam;
