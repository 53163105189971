import React from "react";
import "aos/dist/aos.css";
import { Clock, MapPin } from "react-feather";
import "./Offersgrid.css";
import { useState, useEffect } from "react";
import axios from "axios";

const OfferGrid = () => {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const getdata = async () => {
    const response = await axios.get(
      "https://api.dremerz.net/api/lawnlinkhomeavailableoffer"
    );
    setData(response.data);
    console.log(response.data, "getdata");
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredUsers = data.filter((item) =>
    item.turfname.toLowerCase().includes(searchTerm)
  );

  useEffect(() => {
    getdata();
  }, []);

  return (
    <div className="content new-offersgrid">
      <div className="container">
        <div className="admin-search-bar">
          <input
            type="text"
            placeholder="Search Here..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="admin-search-input"
            style={{
              position: window.innerWidth <= 786 ? "relative" : "fixed",
              marginTop: window.innerWidth <= 786 ? "-135px" : "-71px",
              marginRight: window.innerWidth <= 786 ? "-6px" : "-61px",
              zIndex: window.innerWidth <= 786 ? "999" : "",
              height: window.innerWidth <= 786 ? "50px" : "",
            }}
          />
        </div>

        <section
          className="services"
          style={{ marginTop: window.innerWidth <= 786 ? "-50px" : "" }}
        >
          <div className="section-heading aos" data-aos="fade-up">
            <h2>
              Discover Our <span>Exclusive Offers</span>
            </h2>
          </div>
          <div className="row">
            {filteredUsers
              .slice()
              .reverse()
              .map((offer) => (
                <div
                  className="col-12 col-sm-12 col-md-6 col-lg-3"
                  key={offer.id}
                >
                  <div className="listing-item">
                    <div className="listing-img">
                      <div href="/booknow">
                        <img
                          src={`https://api.dremerz.net/uploads/${offer.imgSrc}`}
                          className="offer-img"
                          alt="Event"
                          width="100%"
                          height="300px"
                        />
                      </div>
                      <div className="date-info text-center">
                        <h2>{offer.offerpercent} %</h2>
                        <h6>Flat</h6>
                      </div>
                    </div>
                    <div className="listing-content">
                      <h4 className="listing-title">
                        <p>{offer.turfname}</p>
                      </h4>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </section>
      </div>
    </div>
  );
};

export default OfferGrid;
