import { useEffect, useState } from "react";
import axios from "axios";
import Navbaradmin from "../Navbaradmin/Navbaradmin";
import "./Contactusadin.css";

const Contactusadmin = () => {
  const [formData, setFormData] = useState({
    email: "",
    mobile1: "",
    mobile2: "",
    location: "",
  });

  const getdata = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/lawnlinkContactInformation"
      );
      const contactData = response.data[0];
      setFormData({
        email: contactData.email || "",
        mobile1: contactData.mobile1 || "",
        mobile2: contactData.mobile2 || "",
        location: contactData.location || "",
      });
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  useEffect(() => {
    getdata();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      formDataToSend.append(key, formData[key]);
    });

    try {
      await axios.put(
        "https://api.dremerz.net/api/lawnlinkContactInformation/1/",
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      alert("Data Saved Successfully");
    } catch (error) {
      console.log("Failed to save data", error);
    }
  };

  return (
    <>
      <Navbaradmin />
      <div className="contact-info-container">
        <h2>Contact Information</h2>
        <form onSubmit={handleSubmit} className="contact-info-form">
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="text"
              className="form-control"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="mobile1" className="form-label">
              Mobile 1
            </label>
            <input
              type="text"
              className="form-control"
              id="mobile1"
              name="mobile1"
              value={formData.mobile1}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="mobile2" className="form-label">
              Mobile 2
            </label>
            <input
              type="text"
              className="form-control"
              id="mobile2"
              name="mobile2"
              value={formData.mobile2}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="location" className="form-label">
              Location
            </label>
            <input
              type="text"
              className="form-control"
              id="location"
              name="location"
              value={formData.location}
              onChange={handleChange}
            />
          </div>

          <button type="submit" className="btn-primary">
            Update
          </button>
        </form>
      </div>
    </>
  );
};

export default Contactusadmin;
