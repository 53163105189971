import Header from "../Home/Header";
import Turfdetailcoursel from "./Turfdetailscoursel";
import Turfinfo from "./Turfinfo";
import Turfdetailcontent from "./Turfdetailcontent";
import Footer from "../Footer/Footer";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Turfdetails = () => {
  const location = useLocation();

  const rdata = location.state.data;
  const [data, setData] = useState(rdata);
  const [showReview, setShowReview] = useState([]);

  useEffect(() => {
    console.log(data, "data");
  }, []);

  return (
    <>
      <Header></Header>

      <Turfdetailcoursel data={data} />

      <Turfinfo
        data={data}
        showReview1={showReview}
        setShowReview={setShowReview}
      />
      <Turfdetailcontent data={data} showReview={showReview} />
      <Footer></Footer>
    </>
  );
};
export default Turfdetails;
