import React, { useState, useEffect } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import axios from "axios";
import "./Turfselectionform.css";
import Header from "../Home/Header";

const Groundform = () => {
  const [minDateTime, setMinDateTime] = useState("");

  const [formData, setFormData] = useState({
    matchType: "",
    entryFee: "",
    dateTime: "",
    rules: "",
    hostBy: "",
    location: "",
    contactNo: "",
    description: "",
    turfname: "",
    fulladdress: "",
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [image, setImage] = useState(null);
  const userData = JSON.parse(sessionStorage.getItem("user"));
  console.log(userData.email, "userdata");

  const handleChange = (event) => {
    const { id, value } = event.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    setImage(selectedImage);
    console.log(selectedImage, "selected image");
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.matchType) newErrors.matchType = "Match Type is required";
    if (!formData.entryFee) newErrors.entryFee = "Entry Fee is required";
    if (!formData.dateTime) newErrors.dateTime = "Date and Time are required";
    if (!formData.hostBy) newErrors.hostBy = "Host By is required";
    if (!formData.location) newErrors.location = "Location is required";
    if (!formData.fulladdress) newErrors.location = "fulladdress is required";

    if (!formData.contactNo) newErrors.contactNo = "Contact Number is required";
    if (!formData.description)
      newErrors.description = "Description is required";
    if (!formData.turfname) newErrors.turfname = "Turf Name is required";
    return newErrors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const data = new FormData();
    data.append("matchType", formData.matchType);
    data.append("entryFee", formData.entryFee);
    data.append("dateTime", formData.dateTime);
    data.append("rules", formData.rules);
    data.append("hostBy", formData.hostBy);
    data.append("location", formData.location.toLowerCase());
    data.append("contactNo", formData.contactNo);
    data.append("description", formData.description);
    data.append("turfname", formData.turfname);
    data.append("fulladdress", formData.fulladdress);
    data.append("officialemail", userData.email);

    data.append("userid", userData.id);
    if (image) {
      data.append("tournamentPoster", image);
    }

    try {
      const response = await axios.post(
        "https://api.dremerz.net/api/lawnlinkeventlist",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.message === "Success") {
        setSuccessMessage("Post successful!");
        setFormData({
          matchType: "",
          entryFee: "",
          dateTime: "",
          rules: "",
          hostBy: "",
          location: "",
          contactNo: "",
          description: "",
          turfname: "",
          fulladdress: "",
        });
        setImage(null);
      } else {
        setErrorMessage("Post failed. Please try again.");
      }
    } catch (error) {
      console.error("Registration failed", error);
      setErrorMessage("An error occurred. Please try again.");
    }
  };
  useEffect(() => {
    // Get the current date and time in ISO format for the datetime-local input
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");

    const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
    setMinDateTime(formattedDateTime);
  }, []);

  return (
    <>
      <Header />
      <div className="custom-form-content">
        <div
          className="custom-form-container"
          style={{ marginTop: window.innerWidth <= 786 ? "" : "60px" }}
        >
          <div className="custom-form-heading aos">
            <h2>
              <span>Events</span> Form
            </h2>
            <br></br>
            <p>Create Your Own Events</p>
          </div>
          <Form onSubmit={handleSubmit}>
            <Form.Group
              className="custom-form-group"
              controlId="tournamentPoster"
            >
              <Form.Label className="custom-form-label">
                Ground: Post Your Tournament Poster
              </Form.Label>
              <Form.Control
                className="custom-file-input"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                required
              />
            </Form.Group>
            <Form.Group className="custom-form-group" controlId="hostBy">
              <Form.Label className="custom-form-label">Host By</Form.Label>
              <Form.Control
                className="custom-form-control"
                as="select"
                value={formData.hostBy}
                onChange={handleChange}
                isInvalid={!!errors.hostBy}
              >
                <option value="">Select</option>
                <option value="turfevent">Turf</option>
                <option value="academyevent">Academy</option>
              </Form.Control>
              <Form.Control.Feedback
                className="custom-form-feedback"
                type="invalid"
              >
                {errors.hostBy}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="custom-form-group" controlId="matchType">
              <Form.Label className="custom-form-label">Match Type</Form.Label>
              <Form.Control
                className="custom-form-control"
                type="text"
                value={formData.matchType}
                onChange={handleChange}
                isInvalid={!!errors.matchType}
              />
              <Form.Control.Feedback
                className="custom-form-feedback"
                type="invalid"
              >
                {errors.matchType}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="custom-form-group" controlId="entryFee">
              <Form.Label className="custom-form-label">Entry Fee</Form.Label>
              <Form.Control
                className="custom-form-control"
                type="number"
                value={formData.entryFee}
                onChange={handleChange}
                isInvalid={!!errors.entryFee}
              />
              <Form.Control.Feedback
                className="custom-form-feedback"
                type="invalid"
              >
                {errors.entryFee}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="custom-form-group" controlId="dateTime">
              <Form.Label className="custom-form-label">
                Date and Time
              </Form.Label>
              <Form.Control
                className="custom-form-control"
                type="datetime-local"
                value={formData.dateTime}
                onChange={handleChange}
                isInvalid={!!errors.dateTime}
                min={minDateTime}
              />
              <Form.Control.Feedback
                className="custom-form-feedback"
                type="invalid"
              >
                {errors.dateTime}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="custom-form-group" controlId="rules">
              <Form.Label className="custom-form-label">Rules</Form.Label>
              <Form.Control
                className="custom-form-control"
                as="textarea"
                rows={3}
                value={formData.rules}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="custom-form-group" controlId="location">
              <Form.Label className="custom-form-label">Location</Form.Label>
              <Form.Control
                className="custom-form-control"
                type="text"
                value={formData.location}
                onChange={handleChange}
                isInvalid={!!errors.location}
              />
              <Form.Control.Feedback
                className="custom-form-feedback"
                type="invalid"
              >
                {errors.location}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="custom-form-group" controlId="fulladdress">
              <Form.Label className="custom-form-label">
                Full Address
              </Form.Label>
              <Form.Control
                className="custom-form-control"
                type="text"
                value={formData.fulladdress}
                onChange={handleChange}
                isInvalid={!!errors.fulladdress}
              />
              <Form.Control.Feedback
                className="custom-form-feedback"
                type="invalid"
              >
                {errors.fulladdress}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="custom-form-group" controlId="contactNo">
              <Form.Label className="custom-form-label">Contact No</Form.Label>
              <Form.Control
                className="custom-form-control"
                type="text"
                value={formData.contactNo}
                onChange={handleChange}
                isInvalid={!!errors.contactNo}
              />
              <Form.Control.Feedback
                className="custom-form-feedback"
                type="invalid"
              >
                {errors.contactNo}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="custom-form-group" controlId="description">
              <Form.Label className="custom-form-label">Description</Form.Label>
              <Form.Control
                className="custom-form-control"
                type="text"
                value={formData.description}
                onChange={handleChange}
                isInvalid={!!errors.description}
                required
              />
              <Form.Control.Feedback
                className="custom-form-feedback"
                type="invalid"
              >
                {errors.description}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="custom-form-group" controlId="turfname">
              <Form.Label className="custom-form-label">Turf Name</Form.Label>
              <Form.Control
                className="custom-form-control"
                type="text"
                value={formData.turfname}
                onChange={handleChange}
                isInvalid={!!errors.turfname}
                required
              />
              <Form.Control.Feedback
                className="custom-form-feedback"
                type="invalid"
              >
                {errors.turfname}
              </Form.Control.Feedback>
            </Form.Group>

            <Button
              className="custom-submit-button"
              variant="primary"
              type="submit"
            >
              Post
            </Button>
          </Form>
          {successMessage && (
            <Alert
              className="custom-alert custom-alert-success"
              variant="success"
            >
              {successMessage}
            </Alert>
          )}
          {errorMessage && (
            <Alert
              className="custom-alert custom-alert-danger"
              variant="danger"
            >
              {errorMessage}
            </Alert>
          )}
        </div>
      </div>
    </>
  );
};

export default Groundform;
