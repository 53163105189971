import { useEffect, useState } from "react";
import axios from "axios";
import { FaArrowRight, FaCalendarAlt } from "react-icons/fa";
import "./Featured-section.css";

const Featuredsection = () => {
  const [data, setData] = useState([]);

  const getdata = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/lawnlinkhomeservices"
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    getdata();
  }, []);

  return (
    <>
      <section className="section featured-section">
        <div className="container">
          <div className="section-heading aos" data-aos="fade-up">
            <h2>
              Our <span>Services</span>
            </h2>
            <p className="sub-title">
              Whether you're looking to refine your skills or take your game to
              the next level, our tailored and professional guidance are here to
              support you every step of the way.{" "}
            </p>
          </div>

          <div
            className="featured-slider-group d-flex flex-nowrap"
            data-aos="fade-up"
            style={{ overflowX: "scroll" }}
          >
            {data?.map((offer) => (
              <div
                className="featured-venues-item flex-shrink-0"
                key={offer.id}
                style={{ width: "25%" }}
              >
                <div className="listing-item mb-0">
                  <div className="listing-img">
                    <a href={offer.link}>
                      <img
                        src={`https://api.dremerz.net/uploads/${offer.imgSrc}`}
                        alt={offer.turfname}
                        style={{ width: "100%", height: "400px" }}
                      />
                    </a>
                  </div>
                  <div className="listing-content list-coche-content">
                    <h3>
                      <a href={offer.link}>{offer.title}</a>
                    </h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Featuredsection;
