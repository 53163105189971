import React, { useState } from "react";
import Footer from "../Footer/Footer";
import "./Signin.css";

const Signin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handlesubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");

    try {
      const response = await fetch(
        `https://api.dremerz.net/api/lawnlinksaveuser?email=${encodeURIComponent(
          email
        )}&password=${encodeURIComponent(password)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
          },
        }
      );

      const data = await response.json();
      console.log(data);

      if (response.ok) {
        if (data.length > 0) {
          sessionStorage.setItem("user", JSON.stringify(data[0]));
          if (data[0].email === "superadmin@gmail.com") {
            window.location.href =
              "/superadmin+asis+2024+jovin+lawnlink+ll+@+aj";
          } else {
            window.location.href = "/";
          }
        } else {
          setErrorMessage("Invalid email or password");
          alert("Invalid email or password");
        }
      } else {
        setErrorMessage("Invalid email or password");
      }
    } catch (err) {
      setErrorMessage("An error occurred. Please try again.");
      console.error(err.message);
    }
  };

  return (
    <>
      <div className="content">
        <div className="container wrapper no-padding">
          <div className="row no-margin vph-100">
            <div className="col-12 col-sm-12 col-lg-6 no-padding signin-left">
              <div className="banner-bg login">
                <div className="row no-margin h-100">
                  <div className="col-sm-10 col-md-10 col-lg-10 mx-auto">
                    <div className="h-100 d-flex justify-content-center align-items-center">
                      <div className="text-bg register text-center">
                        <button
                          type="button"
                          className="btn btn-limegreen text-capitalize"
                        >
                          <i className="fa-solid fa-thumbs-up me-3 userlogin-button"></i>
                          User Login
                        </button>
                        <p>
                          Log in right away for our advanced sports software
                          solutions, created to address issues in regular
                          sporting events and activities.
                        </p>
                        <img
                          src="assets/Homeimg/featuredvenue02.jpeg"
                          className="signin-image"
                          alt="Sign In"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-lg-6 no-padding">
              <div className="dull-pg">
                <div className="row no-margin vph-100 d-flex align-items-center justify-content-center">
                  <div className="col-sm-10 col-md-10 col-lg-10 mx-auto">
                    <header className="text-center">
                      <a href="/home">
                        <img
                          src="Listimg/Lawnlogo.jpeg"
                          className="img-fluid signin-logo"
                          alt="Logo"
                        />
                      </a>
                    </header>
                    <div className="shadow-card">
                      <h2>Welcome Back</h2>
                      <p>Login into your account</p>
                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active d-flex align-items-center"
                            id="user-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#user"
                            type="button"
                            role="tab"
                            aria-controls="user"
                            aria-selected="true"
                          >
                            <span className="d-flex justify-content-center align-items-center"></span>
                            Explore
                          </button>
                        </li>
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="user"
                          role="tabpanel"
                          aria-labelledby="user-tab"
                        >
                          <form onSubmit={handlesubmit}>
                            <div className="form-group">
                              <div className="group-img">
                                <i className="feather-user"></i>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Email"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  required
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="pass-group group-img">
                                <i className="toggle-password feather-eye-off"></i>
                                <input
                                  type="password"
                                  className="form-control pass-input"
                                  placeholder="Password"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  required
                                />
                              </div>
                            </div>
                            <div className="form-group d-sm-flex align-items-center justify-content-between">
                              <span>
                                <a
                                  href="/forgetpassword"
                                  className="forgot-pass"
                                >
                                  Forgot Password
                                </a>
                              </span>
                            </div>
                            <button
                              className="btn btn-secondary register-btn d-inline-flex justify-content-center align-items-center w-100 btn-block"
                              type="submit"
                            >
                              Sign In
                              <i className="feather-arrow-right-circle ms-2"></i>
                            </button>
                            {errorMessage && (
                              <div className="error-message text-center">
                                <p>{errorMessage}</p>
                              </div>
                            )}
                            <div className="form-group">
                              <div className="login-options text-center"></div>
                            </div>

                            <div className="bottom-text text-center">
                              <p>
                                Don’t have an account?
                                <a
                                  href="/signup"
                                  className="newsignin-bottomtext"
                                >
                                  Sign up!
                                </a>
                              </p>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Signin;
