import React, { useEffect, useState } from "react";
import axios from "axios";
import Navbarheader from "../Navbaradmin/Navbaradmin";

const Enquirylist = () => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/LawnLinkEnquiryList"
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Navbarheader />
      <br></br>
      <br></br>

      <br></br>

      <div className="container mt-5">
        <h2>Enquiry List</h2>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Subject</th>
              <th>Comments</th>
            </tr>
          </thead>
          <tbody>
            {data.toReversed().map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.firstname}</td>
                <td>{item.lastname}</td>
                <td>{item.email}</td>
                <td>{item.phone}</td>
                <td>{item.subject}</td>
                <td>{item.comments}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Enquirylist;
