import React, { useState, useEffect } from "react";
import Footer from "../Footer/Footer";
import Header from "../Home/Header";
import "./Faq.css";
import axios from "axios";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [data, setData] = useState([]);

  const getdata = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/lawnlinkhomeExploreFrequentlyAskedQuestions"
      );
      // Assuming response.data is the actual data you need
      setData(Array.isArray(response.data) ? response.data : []);
      console.log(response.data, "getdata");  
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    getdata();
  }, []);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  return (
    <>
      <Header />
      <div className="content new-faq">
        <div className="container">
          <div className="section-heading aos">
            <h2>
              Frequently <span>Asked Questions</span>
            </h2>
          </div>
          <div className="row">
            <div className="col-12 offset-sm-12 offset-md-1 col-md-10 col-lg-10">
              <div className="ask-questions">
                <div className="faq-info">
                  <div className="accordion" id="accordionExample">
                    {data.length > 0 ? (
                      data.map((item, index) => (
                        <div className="accordion-item" key={index}>
                          <h2
                            className="accordion-header"
                            id={`heading${index}`}
                          >
                            <button
                              className={`accordion-button ${
                                activeIndex === index ? "" : "collapsed"
                              }`}
                              type="button"
                              onClick={() => handleToggle(index)}
                              aria-expanded={activeIndex === index}
                              aria-controls={`collapse${index}`}
                            >
                              {item.question}
                            </button>
                          </h2>
                          <div
                            id={`collapse${index}`}
                            className={`accordion-collapse collapse ${
                              activeIndex === index ? "show" : ""
                            }`}
                            aria-labelledby={`heading${index}`}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <div className="accordion-content">
                                <p>{item.answer}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>No frequently asked questions available.</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FAQ;
