import React, { useEffect } from "react";

const AccordionSection = ({ id, title, content, isOpen = false }) => (
  <div className="accordion-item mb-4" id={id}>
    <h4 className="accordion-header" id={`panelsStayOpen-${id}`}>
      <button
        className="accordion-button"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={`#panelsStayOpen-${id}-collapse`}
        aria-expanded={isOpen}
        aria-controls={`panelsStayOpen-${id}-collapse`}
      >
        {title}
      </button>
    </h4>
    <div
      id={`panelsStayOpen-${id}-collapse`}
      className={`accordion-collapse collapse ${isOpen ? "show" : ""}`}
      aria-labelledby={`panelsStayOpen-${id}`}
    >
      <div className="accordion-body">{content}</div>
    </div>
  </div>
);

const ReviewBox = ({ comment, user, date, rating, review }) => (
  <div className="review-box d-md-flex">
    <div className="review-info">
      <h6 className="mb-2 tittle">
        {user} <br></br> {date}
      </h6>
      <div className="rating">
        {[...Array(5)].map((_, index) => (
          <i
            key={index}
            className={`fas fa-star ${index < rating ? "filled" : ""}`}
            style={{ color: index < rating ? "#22B23B" : "gray" }}
          ></i>
        ))}
        <span>{rating}.0</span>
      </div>

      <h6>{comment}</h6>
    </div>
  </div>
);

const GalleryItem = ({ src, thumb }) => (
  <a className="corner-radius-10" href={thumb} data-fancybox="gallery3">
    <img className="img-fluid corner-radius-10" alt="Image" src={src} />
  </a>
);

const Turfdetailcontent = (props) => {
  const overviewContent = (
    <>
      {JSON.parse(props.data.overview).map((item) => (
        <>
          <p>{item.heading}</p>
        </>
      ))}
    </>
  );

  const includesContent = (
    <ul className="clearfix">
      {JSON.parse(props.data.includes).map((item, index) => (
        <li key={index}>
          <i className="feather-check-square"></i>
          {item.item}
        </li>
      ))}
    </ul>
  );

  const rulesContent = (
    <ul>
      {JSON.parse(props.data.rules).map((item, index) => (
        <li key={index}>
          <p>
            <i className="feather-alert-octagon"></i>
            {item.rule}
          </p>
        </li>
      ))}
    </ul>
  );

  const amenitiesContent = (
    <ul className="d-md-flex justify-content-between align-items-center">
      {JSON.parse(props.data.amenities).map((item, index) => (
        <li key={index}>
          <i className="fa fa-check-circle" aria-hidden="true"></i>
          {item.amenity}
        </li>
      ))}
    </ul>
  );

  const reviews = [
    {
      user: "Amanda",
      date: "06/04/2023",
      rating: 3,
      comment: "Absolutely perfect",
    },
  ];
  useEffect(() => {
    console.log(props.showReview);
  }, []);
  return (
    <>
      <div className="content venue-coach-details">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-8">
              {/* <div className="venue-options white-bg mb-4">
                <ul className="clearfix">
                  {[
                    "overview",
                    "includes",
                    "rules",
                    "amenities",
                    "reviews",
                  ].map((section, index) => (
                    <li
                      key={index}
                      className={section === "overview" ? "active" : ""}
                    >
                      <a href={`#${section}`}>
                        {section.charAt(0).toUpperCase() + section.slice(1)}
                      </a>
                    </li>
                  ))}
                </ul>
              </div> */}

              <div className="accordion" id="accordionPanel">
                <AccordionSection
                  id="overview"
                  title="Overview"
                  content={overviewContent}
                  isOpen={true}
                />
                <AccordionSection
                  id="includes"
                  title="Includes"
                  content={includesContent}
                />
                <AccordionSection
                  id="rules"
                  title="Rules"
                  content={rulesContent}
                />
                <AccordionSection
                  id="amenities"
                  title="Amenities"
                  content={amenitiesContent}
                />

                <AccordionSection
                  id="reviews"
                  title="Reviews"
                  content={
                    <>
                      {props?.showReview?.map((review, index) => (
                        <ReviewBox key={index} {...review} />
                      ))}
                    </>
                  }
                />
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-4">
              <div className="venue-right-sidebar">{/* future ads  */}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Turfdetailcontent;
