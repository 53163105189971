import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";

const VenueInfo = ({ details, onShareClick, onReviewClick }) => (
  <div className="venue-info white-bg d-block">
    <div className="container">
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-6">
          <h1 className="d-flex align-items-center justify-content-start">
            {details.turfname}
            <span className="d-flex justify-content-center align-items-center">
              <i className="fas fa-check-double"></i>
            </span>
          </h1>
          <ul className="d-sm-flex justify-content-start align-items-center">
            <li>
              <i className="feather-map-pin"></i>
              {details.fulladdress}
            </li>
            <li>
              <i className="feather-phone-call"></i>
              {details.contactNo}
            </li>
            <li>
              <i className="feather-mail"></i>
              {details.officialemail}
            </li>
          </ul>
        </div>

        <div className="col-12 col-sm-12 col-md-12 col-lg-6 text-right">
          <ul className="social-options float-lg-end d-sm-flex justify-content-start align-items-center">
            <li
              className="venue-review-info d-flex justify-content-start align-items-center"
              onClick={onReviewClick}
            >
              <span className="d-flex justify-content-center align-items-center">
                {details.rating}
              </span>
              <div className="review">
                <div className="rating">
                  {Array.from({ length: details.rating }, (_, i) => (
                    <i key={i} className="fas fa-star filled"></i>
                  ))}
                </div>
                <p className="mb-0">
                  <a href="#">Reviews</a>
                </p>
              </div>
              <i className="fa-regular fa-comments"></i>
            </li>
          </ul>
        </div>
      </div>
      <hr />
      <div className="row bottom-row d-flex align-items-center">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
          <ul className="d-sm-flex details">
            <li>
              <div className="profile-pic">
                <a href="#" className="venue-type">
                  <img
                    className="img-fluid"
                    src="assets/img/icons/venue-type.svg"
                    alt="Icon"
                  />
                </a>
              </div>
              <div className="ms-2">
                <p>Pitch Size</p>
                <h6 className="mb-0">{details.pitchsize}</h6>
              </div>
            </li>
            <li>
              <div className="ms-2">
                <p>Added By</p>
                <h6 className="mb-0">{details.ownername}</h6>
              </div>
            </li>
          </ul>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
          <div className="d-flex float-sm-end align-items-center">
            <p className="d-inline-block me-2 mb-0">Starts From :</p>
            <h3 className="primary-text mb-0 d-inline-block">
              INR{" "}
              {details.priceperhour ? details.priceperhour : details.totalprice}
              <span>/ hr</span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Turfinfo = (props) => {
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [review, setReview] = useState({ user: "", rating: 0, comment: "" });
  const userdata = JSON.parse(sessionStorage.getItem("user"));
  const date = new Date().toISOString().split("T")[0];
  const [showReview, setShowReview] = useState(props.showReview1);

  const handleReviewClick = (e) => {
    e.preventDefault();
    setShowReviewModal(true);
  };

  const handleCloseReview = () => {
    setShowReviewModal(false);
  };

  const handleReviewChange = (e) => {
    const { name, value } = e.target;
    setReview({ ...review, [name]: value });
  };

  const handleReviewSubmit = async (e) => {
    e.preventDefault();
    const reviewData = new FormData();
    reviewData.append("user", review.user);
    reviewData.append("rating", review.rating);
    reviewData.append("comment", review.comment);
    reviewData.append("turfname", props.data.turfname);
    reviewData.append("useremail", userdata.email);
    reviewData.append("date", date);

    try {
      const response = await axios.post(
        "https://api.dremerz.net/api/lawnlinkreview",
        reviewData
      );
      alert("review submitted successfully");
      getreviewdata();

      setReview({ user: "", rating: 0, comment: "" });
    } catch (error) {
      console.error("failed");
    }
    console.log("New review submitted:", review);
    setShowReviewModal(false);
  };
  const getreviewdata = async () => {
    console.log(props.data.turfname, "props.data.turfname");

    const response = await axios.get(
      `https://api.dremerz.net/api/lawnlinkreview?turfname=${props.data.turfname}`
    );
    console.log(response.data, "showreview");

    setShowReview(response.data);
    props.setShowReview(response.data);
  };
  useEffect(() => {
    getreviewdata();
  }, []);

  return (
    <div className="content venue-coach-details">
      <div className="container">
        <VenueInfo details={props.data} onReviewClick={handleReviewClick} />
      </div>

      <Modal show={showReviewModal} onHide={handleCloseReview}>
        <Modal.Header closeButton>
          <Modal.Title>Reviews</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="add-review">
            <h5>Add a Review</h5>
            <Form onSubmit={handleReviewSubmit}>
              <Form.Group className="mb-3" controlId="formUser">
                <Form.Label>Your Name</Form.Label>
                <Form.Control
                  type="text"
                  name="user"
                  value={review.user}
                  onChange={handleReviewChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formRating">
                <Form.Label>Rating</Form.Label>
                <Form.Control
                  as="select"
                  name="rating"
                  value={review.rating}
                  onChange={handleReviewChange}
                  required
                >
                  <option value="0">Select rating</option>
                  {[...Array(5)].map((_, i) => (
                    <option key={i + 1} value={i + 1}>
                      {i + 1}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formComment">
                <Form.Label>Comment</Form.Label>
                <Form.Control
                  as="textarea"
                  name="comment"
                  value={review.comment}
                  onChange={handleReviewChange}
                  rows={3}
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit Review
              </Button>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseReview}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Turfinfo;
