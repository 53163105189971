import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Header from "../Home/Header";

const BookingConfirmation = (props) => {
  const location = useLocation();
  const [data, setData] = useState(props.data);
  const [price, setPrice] = useState(
    props.data?.totalprice != undefined
      ? props.data?.totalprice
      : props.data?.price
  );

  const [userData, setUserData] = useState("");
  const [gst, setGst] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const bookingDetails = [
    { title: data.turfname, value: data.title },
    { title: "Appointment Date", value: data.date },
    {
      title: "Appointment time",
      value: data.time.map((item) => item.selecttime).join(", "),
    },
  ];

  const contactInformation = [
    { title: "Name", value: userData.username },
    { title: "Contact Email Address", value: userData.email },
    { title: "Phone Number", value: userData.mobileNumber },
  ];

  const paymentInformation = [
    {
      title: "Cage Total",
      value: "INR " + (data.totalAddedPrice ? data.totalAddedPrice : price),
    },
    { title: "Gst (18%)", value: gst },
    { title: "Subtotal", value: "INR" + " " + subTotal },
  ];

  useEffect(() => {
    console.log(data.time.join());
    const user = JSON.parse(sessionStorage.getItem("user"));
    setUserData(user);
    console.log(userData, "userdata");
  }, []);

  useEffect(() => {
    if (data.totalAddedPrice) {
      const ngst = (data.totalAddedPrice / 100) * 18;
      setGst(ngst);
      const nsubTotal = parseInt(data.totalAddedPrice) + parseInt(ngst);
      setSubTotal(nsubTotal);
      console.log(nsubTotal, "nsuntotal");
    } else {
      const ngst = (price / 100) * 18;
      setGst(ngst);
      const nsubTotal = parseInt(price) + parseInt(ngst);
      setSubTotal(nsubTotal);
      console.log(nsubTotal, "nsuntotal");
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    var da = data;
    da.subTotal = subTotal;
    setData(da);
    props.setData(da);
    props.setActiveStep(3);

    // navigate("/payment", { state: { data } });
  };

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  console.log(subTotal, "totalprice");

  return (
    <>
      <div className="container">
        <div className="content">
          {/* <section className="booking-steps py-30">
          <div className="container">
            <ul className="d-lg-flex justify-content-center align-items-center">
              {bookingSteps.map((step, index) => (
                <li key={index} className={index === 1 ? "active" : ""}>
                  <h5>
                    <a href="#">
                      <span>{step.step}</span>
                      {step.label}
                    </a>
                  </h5>
                </li>
              ))}
            </ul>
          </div>
        </section> */}

          <div className="content book-cage">
            <div className="container">
              <section className="card mb-40">
                <div className="text-center mb-40">
                  <h3 className="mb-1">Order Confirmation</h3>
                  <p className="sub-title mb-0">
                    Thank you for your order! We're excited to fulfill it with
                    care and efficiency.
                  </p>
                </div>
                <div className="master-academy dull-whitesmoke-bg card">
                  <div className="row d-flex align-items-center justify-content-center">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                      <div className="d-sm-flex justify-content-start align-items-center">
                        <a href="#">
                          <img
                            className="corner-radius-10"
                            src={`https://api.dremerz.net/uploads/${data.image}`}
                            alt="Venue"
                          />
                        </a>
                        <div className="info">
                          <div className="d-flex justify-content-start align-items-center mb-3">
                            {/* <span className="text-white dark-yellow-bg color-white me-2 d-flex justify-content-center align-items-center">
                              4.5
                            </span>
                            <span>300 Reviews</span> */}
                          </div>
                          <h3 className="mb-2">{data.turfname}</h3>
                          <p>{data.address}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                      <ul className="d-sm-flex align-items-center justify-content-evenly">
                        <li>
                          <h3 className="d-inline-block">
                            Total : INR{" "}
                            {data.totalAddedPrice
                              ? data.totalAddedPrice
                              : data.price}
                          </h3>
                          <span></span>
                          <p>{data.time.length} Hours</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
              <section className="card booking-order-confirmation">
                <h5 className="mb-3">Booking Details</h5>
                <ul className="booking-info d-lg-flex justify-content-between align-items-center">
                  {bookingDetails.map((detail, index) => (
                    <li key={index}>
                      <h6>{detail.title}</h6>
                      <p>{detail.value}</p>
                    </li>
                  ))}
                </ul>
                <h5 className="mb-3">Contact Information</h5>
                <ul className="contact-info d-lg-flex justify-content-start align-items-center">
                  {contactInformation.map((contact, index) => (
                    <li key={index}>
                      <h6>{contact.title}</h6>
                      <p>
                        {contact.title === "Contact Email Address" ? (
                          <a
                            href="#"
                            className="__cf_email__"
                            style={{ fontSize: "12px" }}
                          >
                            {contact.value}
                          </a>
                        ) : (
                          contact.value
                        )}
                      </p>
                    </li>
                  ))}
                </ul>
                <h5 className="mb-3">Payment Information</h5>
                <ul className="payment-info d-lg-flex justify-content-start align-items-center">
                  {paymentInformation?.map((payment, index) => (
                    <li key={index}>
                      <h6>{payment.title}</h6>
                      <p className="primary-text">{payment.value}</p>
                    </li>
                  ))}
                </ul>
              </section>
              <div className="text-center btn-row">
                <Link
                  className="btn btn-primary me-3 btn-icon"
                  onClick={() => {
                    props.setActiveStep(1);
                  }}
                >
                  <i className="feather-arrow-left-circle me-1"></i> Back
                </Link>
                <Link
                  className="btn btn-secondary btn-icon"
                  onClick={handleSubmit}
                >
                  Next <i className="feather-arrow-right-circle ms-1"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookingConfirmation;
