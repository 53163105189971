import React, { useState } from "react";
import axios from "axios";

const newsletterData = {
  bannerImg: "assets/img/subscribe-bg.jpg",
  iconImg: "assets/img/icons/subscribe.svg",
  title: "Subscribe to Newsletter",
  description: "Just for you, exciting news updates.",
  inputPlaceholder: "Enter Email Address",
  buttonText: "Subscribe",
};

const Newslettersport = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address.");
      setSuccess("");
      return;
    }

    try {
      await axios.post("https://api.dremerz.net/api/lawnlinkhomenewsletter", {
        email,
      });

      setSuccess("Thank you for subscribing!");
      setError("");
      setEmail("");
    } catch (err) {
      setError("Failed to subscribe. Please try again later.");
      setSuccess("");
    }
  };

  return (
    <section className="section newsletter-sport">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="subscribe-style aos" data-aos="fade-up">
              <div className="banner-blk">
                <img
                  src={newsletterData.bannerImg}
                  className="img-fluid"
                  alt="Subscribe"
                />
              </div>
              <div className="banner-info">
                <img
                  src={newsletterData.iconImg}
                  className="img-fluid"
                  alt="Subscribe"
                />
                <h2>{newsletterData.title}</h2>
                <p>{newsletterData.description}</p>
                <div className="subscribe-blk bg-white">
                  <form onSubmit={handleSubmit}>
                    <div className="input-group align-items-center">
                      <i className="feather-mail"></i>
                      <input
                        type="email"
                        className="form-control"
                        placeholder={newsletterData.inputPlaceholder}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        aria-label="email"
                        required
                      />
                      <div className="subscribe-btn-grp">
                        <button type="submit" className="btn btn-secondary">
                          {newsletterData.buttonText}
                        </button>
                      </div>
                    </div>
                    {error && <p className="text-danger mt-2">{error}</p>}
                    {success && (
                      <p className="text-success mt-2 text-center">{success}</p>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Newslettersport;
