import React, { useState } from "react";
import axios from "axios";
import emailjs from "emailjs-com";

const Payment = (props) => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [data, setData] = useState(props.data);
  const [userData, setUserData] = useState(
    JSON.parse(sessionStorage.getItem("user"))
  );
  console.log(userData, "data");

  const orderSummaryItems = [
    { icon: "fa-regular fa-building", text: data.turfname },
    { icon: "feather-calendar", text: data.date },
    {
      icon: "feather-clock",
      text: data.time.map((item) => item.selecttime).join(", "),
    },
  ];

  const handleTermsChange = (e) => {
    setTermsAccepted(e.target.checked);
  };

  const getLastBookingId = async () => {
    try {
      const response = await fetch(
        "https://api.dremerz.net/api/lawnlinkPayments"
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      const bookingIds = data.map((item) => item.id);
      const lastId =
        bookingIds.length > 0 ? Math.max(...bookingIds.map(Number)) : 0;

      return lastId;
    } catch (error) {
      console.error("Error fetching booking IDs:", error);
      return 0;
    }
  };

  // const handleSavepayment = async (rid) => {
  //   const paymentdata = new FormData();
  //   paymentdata.append("turfname", data.turfname);
  //   paymentdata.append("date", data.date);
  //   paymentdata.append(
  //     "time",
  //     data.time.map((item) => item.selecttime).join(", ")
  //   );
  //   paymentdata.append("price", data.price);
  //   paymentdata.append("totalprice", data.subTotal);
  //   paymentdata.append("name", userData.username);
  //   paymentdata.append("email", userData.email);
  //   paymentdata.append("mobile", userData.mobileNumber);
  //   paymentdata.append("razorPayID", rid);

  //   try {
  //     const lastBookingId = await getLastBookingId();
  //     const newBookingIdNumber = lastBookingId + 1;
  //     const currentDate = new Date();
  //     const day = String(currentDate.getDate()).padStart(2, "0");
  //     const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  //     const year = currentDate.getFullYear();
  //     const newBookingId = `LL${day}${month}${year}${String(
  //       newBookingIdNumber
  //     ).padStart(2, "0")}`;

  //     paymentdata.append("bookingid", newBookingId);

  //     const response = await axios.post(
  //       "https://api.dremerz.net/api/lawnlinkPayments",
  //       paymentdata
  //     );

  //     alert("Payment successful");
  //   } catch (error) {
  //     console.log("Payment failed", error);
  //     alert("Payment failed. Please try again.");
  //   }
  // };

  // Function to send email using EmailJS

  const sendEmail = async (paymentdata) => {
    const emailParams = {
      turfname: paymentdata.get("turfname"),
      date: paymentdata.get("date"),
      time: paymentdata.get("time"),
      price: paymentdata.get("price"),
      totalprice: paymentdata.get("totalprice"),
      name: paymentdata.get("name"),
      email: paymentdata.get("email"),
      mobile: paymentdata.get("mobile"),
      bookingid: paymentdata.get("bookingid"),
      razorPayID: paymentdata.get("razorPayID"),
    };

    try {
      await emailjs.send(
        "service_7fvxkss",
        "template_u8lcyg5",
        emailParams,
        "raGWxa28UGG-RU5XD"
      );
      console.log("Email sent successfully");
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  const handleSavepayment = async (rid) => {
    const paymentdata = new FormData();
    paymentdata.append("turfname", data.turfname);
    paymentdata.append("date", data.date);
    paymentdata.append(
      "time",
      data.time.map((item) => item.selecttime).join(", ")
    );
    paymentdata.append("price", data.price);
    paymentdata.append("totalprice", data.subTotal);
    paymentdata.append("name", userData.username);
    paymentdata.append("email", userData.email);
    paymentdata.append("mobile", userData.mobileNumber);
    paymentdata.append("razorPayID", rid);

    const getCurrentDateTime = () => {
      const now = new Date();

      // Format date and time
      const date = String(now.getDate()).padStart(2, "0"); // Day
      const month = String(now.getMonth() + 1).padStart(2, "0"); // Month (0-11)
      const year = now.getFullYear(); // Year
      const hours = String(now.getHours()).padStart(2, "0"); // Hours
      const minutes = String(now.getMinutes()).padStart(2, "0"); // Minutes
      const seconds = String(now.getSeconds()).padStart(2, "0"); // Seconds

      return `${date}+${month}+${year}_${hours}${minutes}${seconds}`;
    };
    const secretId = `SecIdLL+${getCurrentDateTime()}+${String(
      new Date().getSeconds()
    ).padStart(2, "0")}`;
    paymentdata.append("secretId", secretId);

    try {
      const lastBookingId = await getLastBookingId();
      const newBookingIdNumber = lastBookingId + 1;
      const currentDate = new Date();
      const day = String(currentDate.getDate()).padStart(2, "0");
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const year = currentDate.getFullYear();
      const newBookingId = `LL${day}${month}${year}${String(
        newBookingIdNumber
      ).padStart(2, "0")}`;

      paymentdata.append("bookingid", newBookingId);

      const response = await axios.post(
        "https://api.dremerz.net/api/lawnlinkPayments",
        paymentdata
      );

      await sendEmail(paymentdata);

      alert("Payment successful");
    } catch (error) {
      console.log("Payment failed", error);
      alert("Payment failed. Please try again.");
    }
  };

  const handlepayment = () => {
    if (data.subTotal === "") {
      alert("Something went wrong... try again later.");
    } else {
      const loadScript = (src) => {
        return new Promise((resolve) => {
          const script = document.createElement("script");
          script.src = src;
          script.onload = () => resolve(true);
          script.onerror = () => resolve(false);
          document.body.appendChild(script);
        });
      };

      loadScript("https://checkout.razorpay.com/v1/checkout.js").then(
        (loaded) => {
          if (!loaded) {
            alert("Failed to load Razorpay SDK. Please check your connection.");
            return;
          }

          const options = {
            // key: "rzp_test_FNu62GtN5qZaVx",
            // key_secret: "PRIExQVH2t9DAuFTyS4MIwF7",
            key: "rzp_live_D21nobGzfuqZQV",
            key_secret: "AL7KBgMIE4Ed3fM0o9QxCqot",
            amount: data.subTotal * 100,
            currency: "INR",
            name: "Lawnlink",
            description: "Bookings",
            handler: async function (response) {
              console.log(response);
              alert(response.razorpay_payment_id);
              await handleSavepayment(response.razorpay_payment_id);
            },
            prefill: {
              name: userData.username,
              email: userData.email,
              contact: userData.mobileNumber,
            },
            notes: {
              address: "Razorpay Corporate Office",
            },
            theme: {
              color: "#167C82",
            },
          };
          const pay = new window.Razorpay(options);
          pay.open();
        }
      );
    }
  };

  return (
    <>
      <div className="container">
        <div className="content">
          <section>
            <div className="text-center mb-40">
              <h3 className="mb-1">Payment</h3>
              <p className="sub-title mb-0">
                Secure your booking, complete payment, and enjoy our
                sophisticated facilities
              </p>
            </div>
            <div className="master-academy dull-whitesmoke-bg card mb-40">
              <div className="row d-flex align-items-center justify-content-center">
                <div className="d-sm-flex justify-content-start align-items-center">
                  <a href="#">
                    <img
                      className="corner-radius-10"
                      src={`https://api.dremerz.net/uploads/${data.image}`}
                      alt="Venue"
                    />
                  </a>
                  <div className="info">
                    <h3 className="mb-2">{data.turfname}</h3>
                    <p>{data.description}</p>
                    <p>{data.address}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row checkout">
              <div className="col-lg-7">
                <div className="card booking-details">
                  <h3 className="border-bottom">Order Summary</h3>
                  <ul className="list-unstyled">
                    {orderSummaryItems.map((item, index) => (
                      <li key={index}>
                        <i className={`${item.icon} me-2`}></i>
                        {item.text}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-lg-5">
                <aside className="card payment-modes">
                  <h3 className="border-bottom">Checkout</h3>
                  <div className="form-check d-flex justify-content-start align-items-center policy">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="policy"
                      checked={termsAccepted}
                      onChange={handleTermsChange}
                    />
                    <label className="form-check-label" htmlFor="policy">
                      By clicking 'Proceed', I agree to Lawnlink{" "}
                      <a href="privacy-policy#">Privacy Policy</a> and{" "}
                      <a href="terms-condition#">Terms of Use</a>
                    </label>
                  </div>
                  <div className="d-grid btn-block">
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={!termsAccepted}
                      onClick={handlepayment}
                    >
                      Proceed INR {data.subTotal}
                    </button>
                    <br></br>
                  </div>
                </aside>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Payment;
