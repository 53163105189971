import React, { useState, useEffect } from "react";
import axios from "axios";

const Groupcoaching = () => {
  const [data, setData] = useState([]);

  const getdata = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/lawnlinkOurFeatures"
      );
      setData(response.data);
      console.log(response.data, "getdata");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getdata();
  }, []);

  const features =
    data.length > 0
      ? data.map((item) => ({
          id: item.id,
          imgSrc: `/assets/img/icons/coache-icon-${item.id}.svg`, // Use leading slash for local assets
          title: item.title || "Default Title",
          description: item.detail || "Default description.",
          heading: item.heading || "Default Heading",
        }))
      : [
          {
            id: 1,
            imgSrc: "/assets/img/icons/coache-icon-04.svg", // Use leading slash for local assets
            title: "Default Title 1",
            description: "Default description for Feature 1.",
            heading: "Default Heading 1",
          },
          {
            id: 2,
            imgSrc: "/assets/img/icons/coache-icon-05.svg", // Use leading slash for local assets
            title: "Default Title 2",
            description: "Default description for Feature 2.",
            heading: "Default Heading 2",
          },
          {
            id: 3,
            imgSrc: "/assets/img/icons/coache-icon-06.svg", // Use leading slash for local assets
            title: "Default Title 3",
            description: "Default description for Feature 3.",
            heading: "Default Heading 3",
          },
        ];

  return (
    <>
      <br></br>
      <section className="section group-coaching">
        <div className="container">
          <div className="section-heading aos" data-aos="fade-up">
            <h2>
              Our <span>Features</span>
            </h2>
            <p className="sub-title">
              Join us to access top-tier facilities, connect with Premium Turfs,
              and unlock new levels of performance.
            </p>
          </div>
          <div className="row justify-content-center">
            {features.map(({ id, title, description }) => (
              <div key={id} className="col-lg-4 col-md-6 d-flex">
                <div
                  className="work-grid coaching-grid w-100 aos"
                  data-aos="fade-up"
                >
                  {/* <div className="work-icon">
                  <div className="work-icon-inner">
                    <img src={imgSrc} />
                  </div>
                </div> */}
                  <div className="work-content">
                    <h3>{title}</h3>
                    <p>{description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Groupcoaching;
