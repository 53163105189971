import PrivacyPolicy from "./Privacypolicy";
import TermsAndConditions from "./Termsandconditions";
import Exploreadmin from "./Exploreadmin";
import Explorefaq from "./Explorefaq";
import Navbaradmin from "../Navbaradmin/Navbaradmin";

const Exploreadminhome = () => {
  return (
    <>
      <Navbaradmin />

      <Exploreadmin />
      <Explorefaq />
      <TermsAndConditions />
      <PrivacyPolicy />
    </>
  );
};
export default Exploreadminhome;
