import { useEffect, useState } from "react";
import axios from "axios";
import { FaArrowRight, FaCalendarAlt } from "react-icons/fa";
import "./Featured-section.css";

const Featuredsection = () => {
  const [data, setData] = useState([]);

  const getdata = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/lawnlinkhomepremiumoffers"
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    getdata();
  }, []);

  return (
    <div className="container">
      <section className="section featured-section">
        <div className="container">
          <div className="section-heading aos" data-aos="fade-up">
            <h2>
              Premium <span>Offers</span>
            </h2>
            <p className="sub-title">
              Uplift your Turf game with our featured coaches, personalized
              instruction, and expertise to achieve your goals.
            </p>
          </div>

          <div
            className="featured-slider-group d-flex flex-nowrap"
            data-aos="fade-up"
            style={{ overflowX: "scroll" }}
          >
            {data?.map((offer) => (
              <div
                className="featured-venues-item flex-shrink-0"
                key={offer.id}
                style={{ width: "25%" }}
              >
                <div className="listing-item mb-0">
                  <div className="listing-img">
                    <div>
                      <img
                        src={`https://api.dremerz.net/uploads/${offer.imgSrc}`}
                        alt={offer.turfname}
                        style={{ width: "100%", height: "300px" }}
                      />
                    </div>

                    <div className="fav-item-venues">
                      <span className="tag tag-blue">{offer.tag}</span>
                      <div className="list-reviews coche-star"></div>
                    </div>
                    <div className="hour-list">
                      <h5 className="tag tag-primary">Inr {offer.price}</h5>
                    </div>
                  </div>
                  <div className="listing-content list-coche-content">
                    <span> {offer.offerDetail}</span>
                    <div style={{ height: "70px" }}>
                      <h3 href="/availableoffers">{offer.turfname}</h3>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Featuredsection;
