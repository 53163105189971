import { useEffect, useState } from "react";
import axios from "axios";
import emailjs from "emailjs-com";
import { Spinner } from "react-bootstrap";

const Forgetpassword = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [user, setUser] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const data = await getdata();

      if (data && data.email && data.password && data.username) {
        await emailjs.send(
          "service_gpnniy6",
          "template_ahfppgf",
          {
            email: data.email,
            password: data.password,
            username: data.username,
          },
          "raGWxa28UGG-RU5XD"
        );
        alert("Please Check Your Email Inbox For Login Credentials");
      } else {
        alert("Failed to retrieve user data. Please try again.");
      }
    } catch (error) {
      console.log("Enter valid email", error);
      alert("Error sending email. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const getdata = async () => {
    try {
      const response = await axios.get(
        `https://api.dremerz.net/api/lawnlinksaveuser?email=${email}`
      );
      console.log(response.data, "response");
      if (response.data.length > 0) {
        return response.data[0];
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      return null;
    }
  };

  return (
    <div className="content">
      <div className="container wrapper no-padding">
        <div className="row no-margin vph-100">
          <div className="col-12 col-sm-12 col-lg-6 no-padding signin-left">
            <div className="banner-bg login">
              <div className="row no-margin h-100">
                <div className="col-sm-10 col-md-10 col-lg-10 mx-auto">
                  <div className="h-100 d-flex justify-content-center align-items-center">
                    <div className="text-bg register text-center">
                      <button
                        type="button"
                        className="btn btn-limegreen text-capitalize"
                      >
                        <i className="fa-solid fa-thumbs-up me-3 userlogin-button"></i>
                        Get Password
                      </button>
                      <p>
                        Retrieve your login credentials instantly with our easy
                        'Get Password via Email' service, designed to ensure
                        quick and secure access to your account.
                      </p>
                      <img
                        src="assets/Homeimg/featuredvenue02.jpeg"
                        className="signin-image"
                        alt="Sign In"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-lg-6 no-padding">
            <div className="dull-pg">
              <div className="row no-margin vph-100 d-flex align-items-center justify-content-center">
                <div className="col-sm-10 col-md-10 col-lg-10 mx-auto">
                  <header className="text-center">
                    <a href="/home">
                      <img
                        src="Listimg/Lawnlogo.jpeg"
                        className="img-fluid signin-logo"
                        alt="Logo"
                      />
                    </a>
                  </header>
                  <div className="shadow-card">
                    <h2>Welcome Back</h2>
                    <p>Get Password Via Email</p>
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active d-flex align-items-center"
                          id="user-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#user"
                          type="button"
                          role="tab"
                          aria-controls="user"
                          aria-selected="true"
                        >
                          <span className="d-flex justify-content-center align-items-center"></span>
                          Enter Registered Email
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="user"
                        role="tabpanel"
                        aria-labelledby="user-tab"
                      >
                        <form onSubmit={handleSubmit}>
                          <div className="form-group">
                            <div className="group-img">
                              <input
                                className="form-control"
                                type="email"
                                name="email"
                                id="email"
                                placeholder="Email"
                                value={email}
                                autoComplete="off"
                                onChange={(e) => setEmail(e.target.value)}
                                required
                              />
                            </div>
                          </div>

                          <button
                            className="btn btn-secondary register-btn d-inline-flex justify-content-center align-items-center w-100 btn-block"
                            type="submit"
                          >
                            Get Password
                            <i className="feather-arrow-right-circle ms-2"></i>
                          </button>
                          <div className="form-group">
                            <div className="login-options text-center"></div>
                          </div>

                          <div className="bottom-text text-center">
                            <p>
                              Don’t have an account?
                              <a
                                href="/signup"
                                className="newsignin-bottomtext"
                              >
                                Sign up!
                              </a>
                            </p>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgetpassword;
