import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Home/Header";
import { useState, useEffect } from "react";
import axios from "axios";

const TermsAndConditions = () => {
  const [data, setData] = useState([]);
  const getdata = async () => {
    const response = await axios.get(
      "https://api.dremerz.net/api/lawnlinkhomeExploreTermsAndConditions"
    );
    setData(response.data);
    console.log(response.data, "getdata");
  };

  useEffect(() => {
    getdata();
  }, []);

  return (
    <>
      <Header />
      <div className="content">
        <div
          className="breadcrumb breadcrumb-list mb-0"
          style={{
            height: window.innerWidth <= 786 ? "240px" : "300px",
            marginTop: window.innerWidth <= 786 ? "-40px" : "0px",
          }}
        >
          <div className="container content" style={{ marginLeft: "10px" }}>
            <h1 style={{ color: "white" }}>Terms</h1>
            <ul>
              <li>
                <a href="/home">Home</a>
              </li>
              <li>Terms</li>
              <br></br>
            </ul>
          </div>
        </div>

        <div className="content">
          <div className="container">
            <h3 className="text-center">
              Terms and Conditions of Use for LawnLink Turf Booking
            </h3>
            {data?.map((section, index) => (
              <div key={index} className="terms-section">
                <h3>{section.title}</h3>
                <p>{section.description}</p>
              </div>
            ))}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default TermsAndConditions;
